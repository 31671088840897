import { Injectable, Output, EventEmitter, ElementRef } from "@angular/core";


@Injectable()
export class BlockAdminService {
  @Output() adminPanelOpened: EventEmitter<ElementRef> = new EventEmitter();

  adminPanelOpen(element: ElementRef) {
    this.adminPanelOpened.emit(element);
  }
}