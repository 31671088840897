import { Component, OnInit } from '@angular/core';
import { NguCarousel } from '@ngu/carousel';
import { NewsListFilters } from '../../shared/models/news-filters.model';
import { StoriesService, StoryTypes } from '../../shared/services/stories/stories.service';
import { Story } from '../../shared/models/story.model';

@Component({
  selector: 'app-scout-stories',
  templateUrl: './scout-stories.component.html',
  styleUrls: ['./scout-stories.component.scss']
})
export class ScoutStoriesComponent implements OnInit {
  newsListFilters: NewsListFilters;
  loading: boolean = false;
  newsArticles: Story[] = [];
  carouselOne: NguCarousel;
  totalPages: number;

  constructor(private storiesService: StoriesService) {
    this.newsListFilters = new NewsListFilters(storiesService, StoryTypes.Scouts);
    this.newsListFilters.filtersChanged
      .subscribe((res: any) => {
        this.loadArticles();
      })
  }

  ngOnInit() {
    this.loadArticles();

    this.carouselOne = {
      grid: {xs: 1, sm: 3, md: 3, lg: 3, all: 0},
      slide: 1,
      speed: 400,
      interval: 4000,
      point: {
        visible: false
      },
      load: 3,
      touch: true,
      loop: true
    };
  }

  // @todo: Description for scout stories is not visible for anonymous

  loadArticles() {
    this.loading = true;
    this.storiesService.getLatestNews(this.newsListFilters, StoryTypes.Scouts)
      .subscribe((res: any) => {
        this.newsArticles = res.stories;
        this.totalPages = res.pager.pages;
        this.loading = false;
      })
  }

  paging(direction:number) {
    this.newsListFilters.pageNumber += direction;
    this.loadArticles();
  }
}
