import { Component, OnInit } from '@angular/core';
import { BlockHandlerService } from '../../shared/services/block-handler/block-handler.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.scss']
})
export class JoinUsComponent implements OnInit {
  blocks: {};

  constructor(private blockHandlerService: BlockHandlerService) { }

  ngOnInit() {
    this.blocks = {
      'become-a-bp-fellow': new Observable(),
      'become-a-member-of-honours-programme': new Observable(),
    };

    for (let key of Object.keys(this.blocks)) {
      this.blocks[key] = this.blockHandlerService.getBlockData(key);
    }
  }
}
