import {Component, OnInit, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {BreadcrumbService} from "../../../shared/services/breadcrumb/breadcrumb.service";
import "rxjs/add/operator/filter";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  constructor(private _location: Location,
              public breadCrumbService: BreadcrumbService) {
  }

  ngOnInit() {
  }

  backClicked() {
    this._location.back();
  }
}