import { Injectable } from '@angular/core';
import { BackendApiService } from '../backend-api/backend-api.service';
import { SocialChannel } from '../../models/social-channel.model';
import { Observable } from 'rxjs';

@Injectable()
export class SocialCounterService {

  constructor(private backendApiService: BackendApiService) { }

  /** This function is getting the next upcoming event for the main page block */
  getSocialCounts():Observable<SocialChannel[]> {
    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('social-counter')
      .map((res: any) => {
        let socialChannels: SocialChannel[] = [];
        for (let index in res.data) {
          let channel = new SocialChannel(res.data[index].type);
          channel.setCount(res.data[index].counter);
          socialChannels.push(channel);
        }
        return socialChannels;
      });
  }
}
