import { Paragraph } from './../../models/paragraph.model';
import {Observable} from 'rxjs';
import {BackendApiService} from './../backend-api/backend-api.service';
import {Injectable} from '@angular/core';
import {Block} from '../../models/block.model';
import {ParagraphService} from '../paragraph/paragraph.service';

@Injectable()
export class BlockHandlerService {

  protected displayMode: DisplayModes;

  public data: any;

  constructor(
    private backendApiService: BackendApiService,
    private paragraphService: ParagraphService,
  ) {
    this.displayMode = DisplayModes.Automatic;
  }

  public setDisplayMode(displayMode: DisplayModes) {
    this.displayMode = displayMode;
    return this;
  }

  public getTopSlider(event): Observable<Block> {
    let route = event.url,
      urlSlices = route.split('/'),
      sliderBlockName = '';

    if (route === '/' || route === '') {
      sliderBlockName = 'home';
    } else {
      // Query strings pretty much interfere with our path validation.
      const pagePath = urlSlices[1].split('?')[0];

      switch (pagePath) {
        case 'story':
        case 'news':
          sliderBlockName = 'news';
          break;
        case 'gallery':
          sliderBlockName = 'media-list';
          break;
        case 'team':
          sliderBlockName = 'teams';
          break;
        case 'events':
          sliderBlockName = 'our-actions';
          break;
        case 'event':
          sliderBlockName = 'our-actions';
          break;
        default:
          sliderBlockName = pagePath;
          break;
      }
    }

    this.backendApiService.setRestEndpoint();

    return this.backendApiService.get('blocks/' + sliderBlockName + '-slider')
      .map((res: any) => {
        let input = res.data[0];
        let block: Block = new Block().deserialize(input);

        if (input) {
          if (input.hasOwnProperty('slides_paragraphs') && input.slides_paragraphs !== null) {
            this.paragraphService.getParagraphs(input.slides_paragraphs)
              .subscribe((res: any) => {
                block.setParagraphs(res);
              });
          }
        }

        return block;
      });
  }

  public getBlockData(machineName: string = ''): Observable<Block> {
    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('blocks/' + machineName)
      .map((res: any) => {
        let input = res.data[0];
        let block: Block = new Block().deserialize(input);

        if (input.hasOwnProperty('slides_paragraphs') && input.slides_paragraphs !== null) {
          this.paragraphService.getParagraphs(input.slides_paragraphs)
            .subscribe((res: any) => {
              block.setParagraphs(res);
            });
        }

        return block;
      });
  }

}

export enum DisplayModes {
  Automatic = 0,
  Manual = 1
}
