import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header/header.service';
import { PagetitleService } from '../../services/page-title/pagetitle.service';
import { BreadcrumbService } from '../../shared/services/breadcrumb/breadcrumb.service';
import { BreadCrumbItem } from '../../shared/models/breadcrumb-item.model';

@Component({
  selector: 'app-teams-detail-page',
  templateUrl: './teams-detail-page.component.html',
  styleUrls: ['./teams-detail-page.component.scss']
})
export class TeamsDetailPageComponent implements OnInit {

  constructor(private pageTitleService: PagetitleService,
        private headerService: HeaderService,
        private breadCrumbService: BreadcrumbService) {
    this.pageTitleService.setPageTitle('Team');
    this.pageTitleService.setPrintedTitle('Team');
    this.headerService.setHeaderImage('assets/img/header-img-news.jpg');
    this.headerService.shareButtons(true);
    }

  ngOnInit() {
    this.breadCrumbService.setBreadCrumb([new BreadCrumbItem('Teams', 'teams')]);
  }
}

