import { Component, Input, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {
  private regExp: RegExp = /watch\?v=/i;
  private videoUrl: SafeResourceUrl;

  constructor(private ngxSmartModalService: NgxSmartModalService, private sanitizer: DomSanitizer) {  }

  ngOnInit() {
  }

  public open(item) {
    this.videoUrl = this.getIframeSrc(item);
    this.ngxSmartModalService.setModalData(item, 'videoModal');
    this.ngxSmartModalService.open('videoModal');
  }

  public getIframeSrc(item): SafeResourceUrl {
    let sanitizedUrl;
    sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(item.getVideourl().replace(this.regExp, 'embed/'));
    return sanitizedUrl;
  }

  public modalClose() {
    this.ngxSmartModalService.resetModalData('videoModal');
  }

}
