import { Url } from 'url';
import { Image } from './image.model';

export class Paragraph {
    private id: number;
    private type: string;
    private _image: Image;
    private field_issuu_export: {
      url: string;
      text: string;
    };
    private images: {
      url: Url;
      alt: string;
      src: string;
    }[];
    public text: string;
    public title: string;
    public link: any;
    public files: BlockFiles[];
    public layout?: string;

    deserialize(input: any): Paragraph {
        Object.assign(this, input);
        if (input.hasOwnProperty('image') && input.image != null) {
            this.image = new Image(input.image.url, input.image.alt);
        }

        if (input.hasOwnProperty('files')) {
          this.files = [];
          input.files.forEach(file => {
            this.files.push(
              {
                url: file.url,
                description: file.description,
                cleanUrl: decodeURI(file.url).split('/').slice(-1).pop()
              }
            );
          });
        }
        return this;
    }

    getText(): string {
        return this.text;
    }

    public setType(type: string) {
      this.type = type;
    }

    getType(): string {
        return this.type;
    }

    /**
     * Getter image
     * @return {Image}
     */
    public get image(): Image {
      return this._image;
    }

    /**
     * Setter image
     * @param {Image} value
     */
    public set image(value: Image) {
      this._image = value;
    }

    public get issuu() {
      return this.field_issuu_export;
    }

}
