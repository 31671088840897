import { Component, OnInit, Input } from '@angular/core';
import { Paragraph } from '../../../shared/models/paragraph.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-files-block',
  templateUrl: './files-block.component.html',
  styleUrls: ['./files-block.component.scss']
})
export class FilesBlockComponent implements OnInit {
  @Input() paragraph: Paragraph;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  public goToLink(url: string) {
    if (!url) { return; }

    if (url.indexOf('http') === -1 && url.indexOf('https') === -1) {
      this.router.navigate([url]);
    } else {
      window.open(url, '_blank');
    }
  }
}
