import {Injectable} from '@angular/core';
import {Menu} from "../../models/menu";
import {BackendApiService} from "../../shared/services/backend-api/backend-api.service";
import {Observable} from "rxjs";

@Injectable()
export class MenuService {

  constructor(private backendApiService: BackendApiService) {
  }

  getMenu(type): Observable<Menu[]> {
    this.backendApiService.setBaseEndpoint();
    return this.backendApiService.get('/api/menu_items/' + type)
      .map((res: any) => {
        let menuItems: Menu[] = [];

        for (let i = 0; i < res.length; i++) {
          menuItems.push(
            {
              title: res[i].title,
              enabled: res[i].enabled,
              path: res[i].relative
            }
          );
        }

        return menuItems;
      });
  }
}