import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import { ScoutDonateService } from '../../shared/services/scout-donate/scout-donate.service';
import { ScoutProject } from '../../shared/models/scout-project';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-featured-projects',
  templateUrl: './featured-projects.component.html',
  styleUrls: ['./featured-projects.component.scss']
})
export class FeaturedProjectsComponent implements OnInit{

  /* Message type event listener for proper height for the iframes.
   *
   * @var Function globalListen
   */
  protected globalListen: Function;
  protected maxHeight = 0;

  public projects$: Observable<ScoutProject[]>;

  constructor(elementRef: ElementRef, renderer: Renderer2, private scoutDonateService: ScoutDonateService) {
    this.globalListen = renderer.listen('window', 'message', (message) => {
      if (message.origin === 'https://donate.scout.org' && message.data > this.maxHeight) {
        this.maxHeight = message.data;
        const frames = document.querySelectorAll('.scout-frame');
        for (let i = 0; i < frames.length; ++i) {
          frames[i].setAttribute('height', String(this.maxHeight));
        }
      }
    });
  }

  ngOnInit() {
    this.projects$ = this.scoutDonateService.getProjects();
  }

}
