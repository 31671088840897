import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-baden-powell-fellowship-page',
  templateUrl: './baden-powell-fellowship-page.component.html',
  styleUrls: ['./baden-powell-fellowship-page.component.scss']
})
export class BadenPowellFellowshipPageComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
