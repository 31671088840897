import {Url} from "url";

export class Image {
  private _src: Url;
  private _url: Url;
  private _alt: string;

  constructor(src: Url, alt?: string, url?: Url) {
    if (src) {
      this.src = src;
    }
    if (alt) {
      this.alt = alt;
    }
    if (url) {
      this.url = url;
    }
  }

  /**
   * Getter url
   * @return {Url}
   */
  public get src(): Url {
    return this._src;
  }

  public get url(): Url {
    return this._url;
  }

  /**
   * Getter alt
   * @return {string}
   */
  public get alt(): string {
    return this._alt;
  }

  /**
   * Setter url
   * @param {Url} value
   */
  public set src(value: Url) {
    this._src = value;
  }

  /**
   * Setter alt
   * @param {string} value
   */
  public set alt(value: string) {
    this._alt = value;
  }

  public set url(value: Url) {
    this._url = value;
  }
}