export class ContactData {
  protected firstName: string;
  protected lastName: string;
  protected email: string;
  protected topic: string;
  protected message: string;
  protected termsAndConditions: string;
  protected captcha: string;

  public constructor(
    firstName: string,
    lastName: string,
    email: string,
    topic: string,
    message: string,
    termsAndConditions: string,
    captcha: string,
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.topic = topic;
    this.message = message;
    this.termsAndConditions = termsAndConditions;
    this.captcha = captcha;
  }
}
