import {Image} from './image.model';
import {Url} from 'url';
import * as moment from 'moment';

export class RelatedArticles {
  public categories: string;
  public date: moment.Moment;
  public image: Image;
  public path: Url;
  public title: string;

  deserialize(input: any): RelatedArticles {
    this.categories = input.categories;
    this.date = moment(input.date.value);
    this.image = input.image;
    this.path = input.path;
    this.title = input.title;
    return this;
  }

  public getDate() {
    return this.date.format('DD MMM YYYY');
  }
}