import {Subscription, Observable} from 'rxjs';
import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {BlockHandlerService} from '../../../shared/services/block-handler/block-handler.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MenuService} from '../../../services/menu/menu.service';
import {Menu} from '../../../models/menu';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {BackendApiService} from '../../../shared/services/backend-api/backend-api.service';
import {TermsAndConditionsComponent} from '../../terms-and-conditions/terms-and-conditions.component';
import {NewsLetterData} from '../../../models/newsletter';
import { pardotCountryList } from '../../../shared/constants/pardot-country-list.const';
import { switchMap } from 'rxjs/operators';
import { FormService } from '../../../services/form/form.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  public newsLetterForm: FormGroup;
  public submitAttempt = false;
  public footerMenuItems: Menu[] = [];
  public footerMenuMachineName: string;
  public warningMessage: string;
  public successOrFailure: string;
  public footerMenuAdminPath: string;
  private footerMenuSubscription$: Subscription;
  public blocks: {};
  @ViewChild('termsAndConditions') termsAndConditions: TermsAndConditionsComponent;

  constructor(private _menuService: MenuService,
              private blockHandlerService: BlockHandlerService,
              private ngxSmartModalService: NgxSmartModalService,
              private backendApiService: BackendApiService,
              private formBuilder: FormBuilder,
              public formService: FormService) {
    this.footerMenuMachineName = 'footer';
    this.footerMenuAdminPath = '/admin/structure/menu/manage/' + this.footerMenuMachineName;
    this.createNewsLetterForm();
  }

  ngOnInit() {
    this.footerMenuSubscription$ = this._menuService.getMenu(this.footerMenuMachineName).subscribe((res) => {
      this.footerMenuItems = res;
    });

    this.blocks = {
      'footer-left': new Observable(),
      'copyright': new Observable()
    };

    for (let key of Object.keys(this.blocks)) {
      this.blocks[key] = this.blockHandlerService.getBlockData(key);
    }
  }

  ngOnDestroy() {
    this.footerMenuSubscription$.unsubscribe();
  }

  protected createNewsLetterForm() {
    this.newsLetterForm = this.formBuilder.group({
      email: ['', [Validators.email]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required, Validators.required]],
      country: ['', [Validators.required]],
      termsAndConditions: [false, [Validators.required]],
      captcha: ['']
    });
  }

  private getPostData(): NewsLetterData {
    return new NewsLetterData(
      this.newsLetterForm.get('email').value,
      this.newsLetterForm.get('firstName').value,
      this.newsLetterForm.get('lastName').value,
      this.newsLetterForm.get('country').value,
      this.termsAndConditions.captchaValue
    );
  }

  public openSubscription(): void {
    this.ngxSmartModalService.getModal('newsLetterModal').open();
  }

  private closeModal(): void {
    this.ngxSmartModalService.getModal('newsLetterModal').close();
  }

  public submit(): void {
    this.submitAttempt = true;
    if (this.newsLetterForm.valid && this.termsAndConditions.captchaSuccessful) {
      this.backendApiService.setRestEndpoint();
      const data = this.getPostData();
      this.backendApiService.postNewsletter('newsletter/subscribe', data).pipe(
        switchMap((resp: any) => {
          const pardotData: any = {...data};
          delete pardotData.captcha;
          return this.backendApiService.postPardotNewsletter(pardotData).map(() => resp);
        })
      ).subscribe((resp: any) => {
        this.closeModal();

        this.warningMessage = resp.message;
        this.reset();
        grecaptcha.reset();
        this.newsLetterForm.get('termsAndConditions').setValue(false);
        this.ngxSmartModalService.getModal('newsletterResponse').open();

        if (resp.success) {
          this.successOrFailure = 'Success!';
        } else {
          this.successOrFailure = 'Error!';
        }
      }, (err) => {
        this.closeModal();
        this.warningMessage = err.error.message;
        this.successOrFailure = 'Error!';
        this.reset();
        grecaptcha.reset();
        this.newsLetterForm.get('termsAndConditions').setValue(false);
        this.ngxSmartModalService.getModal('newsletterResponse').open();
      });
    }
  }

  // Resets the form and submit attempt.
  private reset() {
    this.newsLetterForm.reset();
    this.submitAttempt = false;
  }

  public closeResponseModal(): void {
    this.ngxSmartModalService.getModal('newsletterResponse').close();
  }
}
