import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from "rxjs/operators";
import { ProjectService } from '../../shared/services/project/project.service';
import { Project } from '../../shared/models/project.model';
import { BreadcrumbService } from '../../shared/services/breadcrumb/breadcrumb.service';
import { BreadCrumbItem } from '../../shared/models/breadcrumb-item.model';
import { PagetitleService } from '../../services/page-title/pagetitle.service';
import { StoriesService } from '../../shared/services/stories/stories.service';
import { Story } from '../../shared/models/story.model';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
  pageData: Observable<Project> = new Observable;
  newsArticles: Story[] = [];

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private storiesService: StoriesService,
    private breadCrumbService: BreadcrumbService,
    private pageTitleService: PagetitleService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params.pipe(
      switchMap(params => {
        this.pageData = this.projectService.getProject(params['alias']);
        try { window.scrollTo({ left: 0, top: 0, behavior: 'smooth' }); } catch (e) { window.scrollTo(0, 0); }

        return this.pageData
      }),
      switchMap(res => {
        this.breadCrumbService.setBreadCrumb([new BreadCrumbItem('Projects'), new BreadCrumbItem(res.getTitle())]);
        this.pageTitleService.setPageTitle(res.getTitle());

        return this.storiesService.getNewsByProject(res.nid);
      })
    ).subscribe(res => {
      this.newsArticles = res;
    });
  }

  public donate(nid: number) {
    this.router.navigate(['/donate'], { queryParams: { project: nid } });
  }

}