import { StoriesService, StoryTypes } from './../../shared/services/stories/stories.service';
import { Component, OnInit } from '@angular/core';
import { Story } from '../../shared/models/story.model';
import { NewsCategory } from '../../shared/models/news-category.model';
import { EventEmitter } from 'events';
import { Subject } from 'rxjs';
import { NewsListFilters } from '../../shared/models/news-filters.model';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {
  newsListFilters: NewsListFilters;

  loading: boolean = false;
  newsArticles: Story[] = [];
  pager: StoryPager;
  showLoadMore: boolean = false;

  constructor(private storiesService: StoriesService) {
    this.newsListFilters = new NewsListFilters(storiesService);
    this.newsListFilters.filtersChanged
      .subscribe((res: any) => {
        this.newsArticles = [];
        this.loadArticles();
      })
  }

  ngOnInit() {
    this.loadArticles();
  }

  loadArticles() {
    this.loading = true;
    this.storiesService.getLatestNews(this.newsListFilters, StoryTypes.News)
    .subscribe((res: any) => {
      this.newsArticles = this.newsArticles.concat(res.stories);
      this.pager = res.pager;
      this.loading = false;
      this.showLoadMore = !this.pager || (this.pager.pages - 1) > this.pager.current_page;
    })
  }

  loadMore() {
    this.newsListFilters.pageNumber++;
    this.loadArticles();
  }
}
