import { Paragraph } from './../../shared/models/paragraph.model';
import { NguCarousel } from '@ngu/carousel';
import { Component, OnInit, Input, HostListener} from '@angular/core';
import { ParagraphService } from '../../shared/services/paragraph/paragraph.service';
@Component({
  selector: 'app-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss']
})

export class ParagraphComponent implements OnInit {
  screenWidth: number = window.innerWidth;
  imageStyle: string;
  carouselOne: NguCarousel;
  issuuOembed = '';

  @Input() paragraph: Paragraph;

  constructor(
    private paragraphService: ParagraphService
  ) {
    this.setImageStyle();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    this.setImageStyle();
  }

  ngOnInit() {
    this.parseISSUU();
    this.carouselOne = {
      grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
      slide: 1,
      speed: 400,
      interval: 4000,
      point: {
        visible: false
      },
      load: 3,
      touch: true,
      loop: true
    };
  }

  public setImageStyle(): string {
    if (this.screenWidth < 400) {
      this.imageStyle = 'square_min';
    }
    else if (this.screenWidth >= 400 && this.screenWidth < 600) {
      this.imageStyle = 'media_element';
    }
    else if (this.screenWidth >= 600 && this.screenWidth < 850) {
      this.imageStyle = 'landscape';
    }
    else if (this.screenWidth >= 850) {
      this.imageStyle = 'widescreen_max';
    }

    return this.imageStyle;
  }

  private parseISSUU() {
    if (this.paragraph.issuu && this.paragraph.issuu.url) {
      this.paragraphService.getIssuuOembed(this.paragraph.issuu.url).subscribe((res: any) => {
        this.issuuOembed = res.html;
      });
    }
  }
}
