import { Observable } from 'rxjs';
import { SocialCounterService } from './../../shared/services/social/social-counter.service';
import { Component, OnInit } from '@angular/core';
import { SocialChannel } from '../../shared/models/social-channel.model';

@Component({
  selector: 'app-social-counter',
  templateUrl: './social-counter.component.html',
  styleUrls: ['./social-counter.component.scss']
})
export class SocialCounterComponent implements OnInit {
  public socialChannels: Observable<SocialChannel[]>;

  constructor(private socialCounterService: SocialCounterService) { }

  ngOnInit() {
    this.socialChannels = this.socialCounterService.getSocialCounts();
  }

  getSocialClasses(social: SocialChannel):string[] {
    return ['social-item', social.getType()];
  }

}
