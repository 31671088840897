import { Title } from '@angular/platform-browser';
import { Time } from "@angular/common";
import { Url } from "url";
import { Deserializable } from "./deserializable.model";
import * as moment from 'moment';
import { ContentWithParagraphs } from './content-with-paragraphs.model';
import { Image } from './image.model';

export class Event extends ContentWithParagraphs implements Deserializable<Event> {
    private title: string;
    path: Url;
    date_range: string;
    startDate: moment.Moment;
    endDate: moment.Moment | null = null;
    country: string;
    city: string;
    nid: number;
    field_files: BlockFiles[] = [];
    private _image: Image;
    private _editLink: string;

    deserialize(input: any): Event {
        Object.assign(this, input);
        this._editLink = input.edit_link;

        let dateArray = input.date_range.split(' - ');

        if (dateArray.length > 1) {
          this.endDate = moment(dateArray[1]);
        }

        this.startDate = moment(dateArray[0]);

        if (input.hasOwnProperty('image') && input.image != null) {
            this.image = new Image(input.image.url, input.image.alt);
        }
        return this;
    }

    /** Helps to deserialize the data coming from a full node json */
    deserializeDetailPage(input: any): Event {
        for(let i = 0; i < input.field_files.length; i++){
            this.field_files.push(
              {
                url: input.field_files[i].url,
                description: input.field_files[i].description,
                cleanUrl: /[^/]*$/.exec(decodeURI(input.field_files[i].url))[0]
              }
            );
        }
        this.nid = input.nid[0].value;
        this.title = input.title[0].value;
        this.startDate = moment(input.field_date_range[0].value);
        this.endDate = moment(input.field_date_range[0].end_value);
        this.city = input.field_address[0].locality;
        this.country = input.field_address[0].country_code;
        this._editLink = "/node/" + input.nid[0].value + "/edit";
        if (input.hasOwnProperty('field_image') && input.field_image.length > 0) {
            this.image = new Image(input.field_image[0].url, input.field_image[0].alt);
        }

        return this;
    }

    getTitle(): string {
        return this.title;
    }

    getUrl(): Url {
        return this.path;
    }

    getCountry(): string {
        return this.country;
    }

    getCity(): string {
        return this.city;
    }

    getDate(): string {
      let date = this.startDate.format('DD MMM YYYY');
      if (this.endDate && this.endDate.format('DD MMM YYYY') !== this.startDate.format('DD MMM YYYY')) {
        date += " - " + this.endDate.format('DD MMM YYYY');
      }
      return date;
    }

    getPath(): Url {
        return this.path;
    }

    /**
     * Getter image
     * @return {Image}
     */
	public get image(): Image {
		return this._image;
	}

    hasImage(): boolean {
        return this.image ? true : false;
    }

    public getImageUrl() : Url {
        if (this.hasImage()) {
        return this._image.src;
        }
    }

    /**
     * Setter image
     * @param {Image} value
     */
	public set image(value: Image) {
		this._image = value;
	}

    public get editLink(): string {
        return this._editLink;
    }
}
