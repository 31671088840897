import { Component, OnInit } from '@angular/core';
import { PagetitleService } from '../../services/page-title/pagetitle.service';
import { HeaderService } from '../../services/header/header.service';

@Component({
  selector: 'app-projectdetailpage',
  templateUrl: './project-detail-page.component.html',
  styleUrls: ['./project-detail-page.component.scss']
})
export class ProjectDetailPageComponent implements OnInit {

  constructor(private pageTitleService: PagetitleService, private headerService: HeaderService) {
    this.pageTitleService.setPrintedTitle('Project');
    this.headerService.setHeaderImage('assets/img/header-img-about-wsf.jpg');
    this.headerService.shareButtons(true);
  }

  ngOnInit() {
  }

}
