import { Component, OnInit } from '@angular/core';
import { PagetitleService } from '../../services/page-title/pagetitle.service';
import { HeaderService } from '../../services/header/header.service';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.scss']
})
export class MainpageComponent implements OnInit {

  constructor(private pageTitleService: PagetitleService, private headerService: HeaderService) {
    pageTitleService.setPageTitle('Home');
    pageTitleService.setPrintedTitle('');
    headerService.setHeaderImage('home-slider');
    this.headerService.shareButtons(false);
  }

  ngOnInit() {
  }
}
