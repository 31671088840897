import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { CountryItem } from '../../shared/models/form.model';
import { WindowService } from '../window/window.service';
import countryList, { overwrite } from 'country-list';

@Injectable()
export class FormService {
  public countries: CountryItem[];
  public days: number[] = FormService.getDays();
  public months: string[] = FormService.getMonths();
  public years: number[] = FormService.getYears();

  constructor(private windowService: WindowService) {
    overwrite([
      { code: 'TW', name: 'Taiwan'},
    ]);

    this.countries = countryList.getData()
      .map(item => ({
        name: item.name,
        alpha2Code: item.code
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  public static getDays(): number[] {
    const days = [];

    for (let i = 0; i < 31; i++) {
      days.push(i + 1);
    }

    return days;
  }

  public static getMonths(): string[] {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    return months;
  }

  public static getYears(count = 100): number[] {
    const years = [],
      now = new Date().getFullYear();

    for (let i = now - count; i <= now; i++) {
      years.push(i);
    }

    return years;
  }

  public print() {
    this.windowService.nativeWindow.print();
  }
}
