import { Component, OnInit } from '@angular/core';
import { PagetitleService } from '../../services/page-title/pagetitle.service';
import { HeaderService } from '../../services/header/header.service';

@Component({
  selector: 'app-newsdetailpage',
  templateUrl: './news-detail-page.component.html',
  styleUrls: ['./news-detail-page.component.scss']
})
export class NewsDetailPageComponent implements OnInit {

  constructor(private pageTitleService: PagetitleService, private headerService: HeaderService) {
    this.pageTitleService.setPageTitle('News');
    this.pageTitleService.setPrintedTitle('News');
    this.headerService.setHeaderImage('assets/img/header-img-news-detail.jpg');
    this.headerService.shareButtons(true);
  }

  ngOnInit() {
  }

}
