import {Url} from "url";

export class BreadCrumbItem {
  private _title: string;
  private _url: string;

  constructor(title: string, url?: string) {
    this._title = title;
    if (url) {
      this._url = url;
    }
  }

  public get title(): string {
    return this._title;
  }

  public get url(): string {
    return this._url;
  }
}