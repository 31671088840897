import {Media} from './../../models/media.model';
import {Injectable} from '@angular/core';
import {BackendApiService} from '../backend-api/backend-api.service';

@Injectable()
export class MediaService {
  public currentPage: number;
  public total: number;

  constructor(private backendApiService: BackendApiService) {
  }

  getGalleryData(machineName: string) {
    this.backendApiService.setNodeEndpoint();
    return this.backendApiService.get(machineName)
       .map((res: any) => new Media().deserializeDetailPage(res));
  }

  /** This function is getting the next upcoming event for the main page block */
  getLatestMedia() {
    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('media/home')
      .map((res: any) => new Media().deserialize(res.data[0]));
  }

  public getMediaList(filter, page) {
    let params: {type?: string, page?} = {};
    params.type = filter.type;
    params.page = page - 1;

    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('media/list', params)
      .map((res: any) => {
        this.total = res.pager.count;
        this.currentPage = res.pager.current_page + 1;

        let mediaList: Media[] = [];
        for (let mediaIndex in res.data) {
          mediaList.push(new Media().deserialize(res.data[mediaIndex]));
        }

        return mediaList;
      });
  }
}
