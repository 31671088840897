
import {throwError as observableThrowError, Observable, Subject, BehaviorSubject} from 'rxjs';
import 'rxjs/add/operator/catch';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from './../../../../environments/environment';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable()
export class BackendApiService {
  @Output() forceUserLogout: EventEmitter<null> = new EventEmitter();
  isAdmin: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // We have two endpoints, this can be rest or node, this flag defines which url to call
  private endpointType: EndpointTypes = EndpointTypes.rest;

  constructor(public http: HttpClient) {
    this.setNodeEndpoint();
    this.isAuthenticated().subscribe((res: any) => {
      if (res === true) {
        this.isAdmin.next(true);
      }
    })
  }

  setRestEndpoint() {
    this.endpointType = EndpointTypes.rest;
  }

  setRestV2Endpoint() {
    this.endpointType = EndpointTypes.restV2;
  }

  setNodeEndpoint() {
    this.endpointType = EndpointTypes.node;
  }

  setBaseEndpoint() {
    this.endpointType = EndpointTypes.base;
  }

  // @todo: Think about a smarter way of making this decision
  decideEndpointRoot(endpoint: string): string {
    if (this.endpointType == EndpointTypes.node) {
      return environment.nodeApiURL;
    }
    else if (this.endpointType == EndpointTypes.rest) {
      return environment.customApiURL;
    }
    else if (this.endpointType == EndpointTypes.restV2) {
      return environment.customApiURLv2;
    }
    else {
      return environment.baseURL
    }
  }

  getThirdParty(endpoint: string, paramsObj?: any) {
    let params = new HttpParams();
    if (paramsObj) {
      Object.keys(paramsObj).forEach(key => {
        params = params.set(key, paramsObj[key]);
      });
    }

    return this.http.get(endpoint, { params });
  }

  get(endpoint: string, params?: any, reqOpts?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('_format', 'json');
    if (params) {
      Object.keys(params).forEach(key => {
        httpParams = httpParams.set(key, params[key]);
      });
    }

    return this.http
      .get(this.decideEndpointRoot(endpoint) + endpoint, {params: httpParams, withCredentials: true})
      .map((res: any) => res)
      .catch(res => {
        const respObj = res.data || res;
        return observableThrowError({errorMessage: respObj.error.message || respObj.error});
      });
  }

  post(endpoint: string, params: any) {
    return this.http
      .post(this.decideEndpointRoot(endpoint) + endpoint + '?_format=json', params)
      .map((res: any) => res);
  }

  postNewsletter(endpoint: string, params: any) {
    return this.http
      .post(this.decideEndpointRoot(endpoint) + endpoint, params)
      .map((res: any) => res);
  }

  postPardotNewsletter(data: any) {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const newData = {
      email: data.email,
      name: data.firstName,
      lastName: data.lastName,
      country: data.country
    }
    const queryString = Object.keys(newData).map(key => key + '=' + newData[key]).join('&');

    return this.http
      .post(environment.pardotUrl, queryString, { headers });
  }

  objectPost(endpoint: string, params: any, reqOpts?: any) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    // Set default request options
    if (!reqOpts || !reqOpts.headers) {
      reqOpts = {
        headers: headers,
        withCredentials: true
      };
    }

    return this.http
      .post(this.decideEndpointRoot(endpoint) + endpoint, params, reqOpts)
      .map((res: any) => JSON.parse(res._body));
  }

  rawPost(endpoint: string, params: any, parse: boolean = true, reqOpts?: any) {
    // Set default request options
    if (!reqOpts || !reqOpts.headers) {
      reqOpts = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        withCredentials: true,
        //body: JSON.stringify(params)
      };
    }

    return this.http
      .post(this.decideEndpointRoot(endpoint) + endpoint, JSON.stringify(params), reqOpts)
      .map((res: any) => res);
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    return this.http
      .put(this.decideEndpointRoot(endpoint) + endpoint, body, reqOpts)
      .map((res: any) => JSON.parse(res._body));
  }

  delete(endpoint: string, reqOpts?: any) {
    return this.http
      .delete(this.decideEndpointRoot(endpoint) + endpoint, reqOpts)
      .map((res: any) => JSON.parse(res._body));
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http
      .put(this.decideEndpointRoot(endpoint) + endpoint, body, reqOpts)
      .map((res: any) => JSON.parse(res._body));
  }

  // This is just an ugly workaround for now
  isAuthenticated() {
    let endpoint = 'user/login';
    return this.http
      .get(this.decideEndpointRoot(endpoint) + endpoint, {withCredentials: true})
      .map((res: any) => res)
      .catch(res => {
        if (res.url.indexOf("user/login") === -1) {
          return Observable.of(true);
        }
        return Observable.of(false);
      });
  }
}

enum EndpointTypes {
  rest,
  restV2,
  node,
  base
}
