import { Component, Input, OnInit } from '@angular/core';
import { RelatedArticles } from '../../shared/models/related-articles';

@Component({
  selector: 'app-related-articles',
  templateUrl: './related-articles.component.html',
  styleUrls: ['./related-articles.component.scss']
})
export class RelatedArticlesComponent implements OnInit {
  @Input('relatedArticles') relatedArticles: RelatedArticles[] = [];
  public absolutePath: string = document.location.href;

  constructor() {
  }

  ngOnInit() {
  }

}