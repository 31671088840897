import {Image} from './image.model';
import {Injectable} from '@angular/core';
import {ContentWithParagraphs} from './content-with-paragraphs.model';
import {RelatedArticles} from './related-articles';
import { SidebarTemplate } from './sidebar-template.model';

@Injectable()
export class BasicPage extends ContentWithParagraphs {
  private title: string;
  private jobTitle: string;
  private _editLink: string;
  private _image: Image;
  public relatedArticles: RelatedArticles[] = [];
  public fieldFiles: BlockFiles[] = [];
  public sidebarTemplate: SidebarTemplate;

  deserialize(input: any): BasicPage {
    this._editLink = '/node/' + input.nid[0].value + '/edit';
    this.title = input.title[0].value;
    if (input.hasOwnProperty('relatedArticles') && input.relatedArticles != null) {
      for (let i = 0; i < input.relatedArticles.length; i++) {
        this.relatedArticles.push(new RelatedArticles().deserialize(input.relatedArticles[i]));
      }
    }
    if (input.hasOwnProperty('jobTitles')) {
      this.jobTitle = input.jobTitles[0];
    }
    if (input.hasOwnProperty('field_image') && input.field_image != null && input.field_image[0]) {
      this._image = new Image(input.field_image[0].url, input.field_image[0].alt);
    }

    if (input.hasOwnProperty('field_files')) {
      for (let i = 0; i < input.field_files.length; i++) {
        this.fieldFiles.push(
          {
            url: input.field_files[i].url,
            description: input.field_files[i].description,
            cleanUrl: /[^/]*$/.exec(decodeURI(input.field_files[i].url))[0]
          }
        );
      }
    }

    if (input.hasOwnProperty('sidebarTemplateParagraphs')) {
      const templateEditLink = input.field_sidebar_template
        && input.field_sidebar_template.length
        && input.field_sidebar_template[0].target_id
        && '/node/' + input.field_sidebar_template[0].target_id + '/edit';

      this.sidebarTemplate = {
        edit_link: templateEditLink || this._editLink,
        paragraphs: input.sidebarTemplateParagraphs
      };
    }

    return this;
  }

  getTitle(): string {
    return this.title;
  }

  getJobTitle(): string {
    return this.jobTitle;
  }

  get editLink(): string {
    return this._editLink;
  }

  get image(): Image {
    return this._image;
  }
}
