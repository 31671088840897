import { Injectable } from '@angular/core';
import { BackendApiService } from '../backend-api/backend-api.service';
import { Observable } from 'rxjs';
import { Story } from '../../models/story.model';
import { AnnualReport } from '../../models/annual-report.model';

@Injectable()
export class AnnualReportService {

  constructor(private backendApiService: BackendApiService) { }

  /** This function is getting the annual reports for the news page blocks */
  getAnnualReports(): Observable<AnnualReport[]> {
    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('news/annual-reports')
      .map((res: any) => {
        let reports: AnnualReport[] = [];
        for (let storyIndex in res.data) {
          reports.push(new AnnualReport().deserialize(res.data[storyIndex]));
        } 
        return reports;
      });
  }

  
}
