import {SafeHtml, SafeResourceUrl} from '@angular/platform-browser';

export class ScoutProject {
  protected id: string;
  protected embedUrl: SafeResourceUrl;

  public constructor(id: string, embedUrl: SafeResourceUrl) {
    this.id = id;
    this.embedUrl = embedUrl;
  }

  public getId(): string {
    return this.id;
  }

  public getEmbedUrl(): SafeResourceUrl {
    return this.embedUrl;
  }
}
