import {Injectable} from '@angular/core';
import {BackendApiService} from '../../shared/services/backend-api/backend-api.service';

@Injectable()
export class SearchService {
  constructor(private backendApiService: BackendApiService) {
  }

  public getSearchResults(searchString: string, page: number): any {
    let params: { 'keys'?: string, page? } = {};
    params.page = page - 1;
    params['keys'] = searchString;
    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('search', params)
      .map((res: any) => {
        return res;
      });
  }
}