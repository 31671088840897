import {Injectable} from '@angular/core';
import {BackendApiService} from '../backend-api/backend-api.service';
import {Event} from '../../models/event.model';
import {Observable} from 'rxjs';
import {ParagraphService} from '../paragraph/paragraph.service';

@Injectable()
export class EventsService {
  eventListCurrentPage: number;
  eventListTotalPages: number;

  constructor(private paragraphService: ParagraphService, private backendApiService: BackendApiService) {
  }

  /** Getting the next upcoming event for the main page block */
  getNextEvent() {
    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('events/home')
      .map((res: any) => new Event().deserialize(res.data[0]));
  }

  /** Getting events for the event list page */
  getEvents(filters) {
    let params: {page?: number} = {};
    if (filters.pageNumber > 0) {
      params.page = filters.pageNumber;
    }
    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('events/list', params)
      .map((res: any) => {
        let events: Event[] = [];
        for (let event of res.data) {
          events.push(new Event().deserialize(event));
        }
        this.eventListTotalPages = res.pager.pages;
        this.eventListCurrentPage = res.pager.current_page;
        return events;
      });
  }

  // Function to return pager data to controller
  getEventListPager(): number[] {
    let pager: number[] = [];
    pager.push(this.eventListCurrentPage, this.eventListTotalPages);
    return pager;
  }

  /** Getting information for the detail page */
  getEventData(machineName: string): Observable<Event> {
    this.backendApiService.setNodeEndpoint();
    return this.backendApiService.get(machineName)
      .map((res: any) => {
        let page = new Event().deserializeDetailPage(res);

        let paragraphIds: number[] = [];
        for (let paragraph of res.field_paragraphs) {
          paragraphIds.push(paragraph.target_id);
        }

        this.paragraphService.getParagraphs(paragraphIds)
          .subscribe((res: any) => {
            page.setParagraphs(res);
          });

        return page;
      });
  }

  getEventDetailGallery(id): Observable<any> {
    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('referenced/media/' + id)
      .map((res: any) => {
        let eventGalleryBlock: EventDetailSideGallery[] = [];
        for (let i = 0; i < res.data.length; i++) {
          eventGalleryBlock.push(
            {
              image: res.data[i].image,
              images: res.data[i].images,
              path: res.data[i].path,
              title: res.data[i].title,
              type: res.data[i].type,
              video: res.data[i].video,
            }
          );
        }

        return eventGalleryBlock;
      });
  }
}