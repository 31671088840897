import { Observable } from 'rxjs';
import { BlockHandlerService } from './../../shared/services/block-handler/block-handler.service';
import { Component, OnInit } from '@angular/core';
import { Block } from '../../shared/models/block.model';

@Component({
  selector: 'app-about-wsf',
  templateUrl: './about-wsf.component.html',
  styleUrls: ['./about-wsf.component.scss']
})
export class AboutWsfComponent implements OnInit {
  blocks: {};
  constructor(private blockHandlerService: BlockHandlerService) { }

  ngOnInit() {
    this.blocks = {
      'home-about-image': new Observable(),
      'home-about-left': new Observable(),
      'home-about-right': new Observable(),
    };

    for (let key of Object.keys(this.blocks)) {
      this.blocks[key] = this.blockHandlerService.getBlockData(key);
    }
  }
}