import {Deserializable} from "./deserializable.model";
import {ContentWithParagraphs} from "./content-with-paragraphs.model";
import {Image} from "./image.model";
import {Url} from "url";

export class Teams extends ContentWithParagraphs implements Deserializable<Teams> {
  private name: string;
  private type: string;
  private image: Image;
  private summary: string;
  private jobTitle: string;
  private path: Url;
  private _editLink: string;
  private description: string;

  deserialize(input: any): Teams {
    Object.assign(this, input);
    this._editLink = input.edit_link;

    if (input.hasOwnProperty('image') && input.image != null) {
      this.image = new Image(input.image.url, input.image.alt);
    }

    if (input.job_title) {
      this.jobTitle = input.job_title.join(", ");
    }
    
    this.name = input.title;

    return this;
  }

  public get editLink(): string {
    return this._editLink;
  }

  getImage(): Image {
    return this.image;
  }

  getName(): string {
    return this.name;
  }

  getType(): string {
    return this.type;
  }

  getSummary(): string {
    return this.summary;
  }

  getTitle(): string {
    return this.jobTitle;
  }

  getPath(): Url {
    return this.path;
  }

  getDescription(): string {
    return this.description;
  }
}