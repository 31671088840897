import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FormGroup } from '@angular/forms';
import { BlockHandlerService } from '../../shared/services/block-handler/block-handler.service';
import { Observable } from 'rxjs';
import { Block } from '../../shared/models/block.model';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  captchaSiteKey = environment.captchaSiteKey;
  captchaSuccessful = false;
  captchaValue: string;
  termsAndConditionsText: Observable<Block> = new Observable();
  @Input() blockId: string;
  @Input() group: FormGroup;
  @Input() id: string;
  @ViewChild('reCaptcha') reCaptcha: RecaptchaComponent;

  constructor(private blockHandlerService: BlockHandlerService) { }

  ngOnInit() {
    this.termsAndConditionsText = this.blockHandlerService.getBlockData(this.blockId);
  }

  captchaResolved(captchaResponse: string) {
    this.captchaValue = captchaResponse;
    this.captchaSuccessful = true;
  }
}
