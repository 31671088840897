import {Injectable} from '@angular/core';
import {ProjectModel} from '../../../models/project';
import { BackendApiService } from '../backend-api/backend-api.service';
import {Observable} from "rxjs";
import { Project } from '../../models/project.model';
import { ParagraphService } from '../paragraph/paragraph.service';

@Injectable()
export class ProjectService {

  constructor(private backendApiService: BackendApiService, private paragraphService: ParagraphService) {
  }

  getProject(alias: string): Observable<Project> {
    this.backendApiService.setBaseEndpoint();
    return this.backendApiService.get('/projects/' + alias)
      .map((res: any) => {
        let page = new Project().deserializeDetailPage(res);

        let paragraphIds: number[] = [];
        for (let paragraph of res.field_paragraphs) {
          paragraphIds.push(paragraph.target_id);
        }

        this.paragraphService.getParagraphs(paragraphIds)
          .subscribe((res: any) => {
            page.setParagraphs(res);
          });

        return page;
      });
  }

  getProjects(): Observable<ProjectModel[]> {
    this.backendApiService.setBaseEndpoint();
    return this.backendApiService.get('/rest/v1/projects/list')
      .map((res: any) => {
        let projects: ProjectModel[] = [];
        res.data.forEach(project => {
          projects.push({
            nid: project.nid,
            title: project.title,
            description: project.summary,
            image: project.image.url,
            small_image: project.field_image_export_1.url,
            url: project.path
          } as ProjectModel);
        });
        return projects;
      });
  }
}
