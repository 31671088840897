import {Title} from '@angular/platform-browser';
import {Time} from "@angular/common";
import {Url} from "url";
import {Deserializable} from "./deserializable.model";
import * as moment from 'moment';
import { Image } from './image.model';


export class Media implements Deserializable<Media> {
  private title: string;
  path: string;
  date: any;
  image: Image;
  public body: string;
  protected _images: any[] = [];
  protected video: Url;
  protected _editLink: string;

  deserialize(input: any): Media {
    if (input.video) {
      this.video = input.video;
    }

    if (input.image) {
      this.image = new Image(input.image.url, input.image.alt);
      delete input.image;
    } else if (input.images && input.images.length !== 0) {
      input.image = new Image(input.images[0].url, input.images[0].alt);
    } else if (this.getVideourl()) {
      input.image = new Image('https://img.youtube.com/vi/' + this.getVideourl().split("=").pop() + '/hqdefault.jpg', 'test');
    }

    Object.assign(this, input);
    this._editLink = input.edit_link;
    this.date = moment(input.date).format('DD MMM YYYY');
    return this;
  }

  deserializeDetailPage(input: any): Media {
    this._editLink = "/node/" + input.nid[0].value + "/edit";
    this.title = input.title[0].value;

    if (input.body.length !== 0) {
      this.body = input.body[0].value;
    }

    for (let image of input.field_images) {
      this._images.push(
        {
          small: image.url,
          medium: image.url,
          big: image.url,
          description: image.alt,
          label: image.alt
        }
      );
    }

    return this;
  }

  getTitle(): string {
    return this.title;
  }

  getPath(): string {
    return this.path;
  }

  hasImage(): boolean {
    return this.image ? true : false;
  }

  getVideourl(): Url {
    return this.video;
  }

  getDate() {
    return this.date;
  }

  get editLink(): string {
    return this._editLink;
  }

  get galleryImages(): any[] {
    return this._images;
  }
}
