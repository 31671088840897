import { Component, OnDestroy, OnInit } from '@angular/core';
import {PagetitleService} from '../../services/page-title/pagetitle.service';
import {HeaderService} from '../../services/header/header.service';
import {Event} from "../../shared/models/event.model";
import {EventsService} from "../../shared/services/events/events.service";
import {BreadCrumbItem} from "../../shared/models/breadcrumb-item.model";
import {BreadcrumbService} from "../../shared/services/breadcrumb/breadcrumb.service";
import {Observable} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-eventdetailpage',
  templateUrl: './eventdetailpage.component.html',
  styleUrls: ['./eventdetailpage.component.scss']
})
export class EventdetailpageComponent implements OnInit, OnDestroy {
  pageData: Observable<Event> = new Observable;
  fieldFiles: BlockFiles[] = [];
  eventGalleryBlock: EventDetailSideGallery[] = [];

  constructor(private pageTitleService: PagetitleService,
              private headerService: HeaderService,
              private eventsService: EventsService,
              private breadCrumbService: BreadcrumbService,
              private route: ActivatedRoute) {
    this.headerService.setHeaderImage('assets/img/header-img-event-detail.jpg');
    this.headerService.shareButtons(true);
  }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        this.pageData = this.eventsService.getEventData('event/' + params['alias']);
      });
    this.pageData.subscribe((res: Event) => {
      this.fieldFiles = res.field_files;
      this.eventsService.getEventDetailGallery(res.nid).subscribe((data) => {
        this.eventGalleryBlock = data;
      });
      this.breadCrumbService.setBreadCrumb([new BreadCrumbItem('Scouts in action', '/our-actions'), new BreadCrumbItem(res.getTitle())]);
      this.pageTitleService.setPageTitle('Event Detail');
      this.pageTitleService.setPrintedTitle('Event');
      this.pageTitleService.setPageInfo('<div class="event-info-wrapper">' +
          '<div class="date-info">' + res.getDate() + '</div>' +
          '<div class="location-info">' + res.getCity() + ', ' + res.getCountry() + '</div>' +
        '</div>');
    });
  }

  ngOnDestroy() {
    this.pageTitleService.setPageInfo('');
  }
}
