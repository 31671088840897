import { Url } from "url";
import { Deserializable } from "./deserializable.model";
import * as moment from 'moment';
import { ContentWithParagraphs } from './content-with-paragraphs.model';
import { Image } from './image.model';

export class Project extends ContentWithParagraphs implements Deserializable<Project> {
    private title: string;
    path: Url;
    nid: number;
    private _image: Image;
    private _editLink: string;

    deserialize(input: any): Project {
        Object.assign(this, input);
        this._editLink = input.edit_link;

        if (input.hasOwnProperty('image') && input.image != null) {
            this.image = new Image(input.image.url, input.image.alt);
        }
        return this;
    }

    /** Helps to deserialize the data coming from a full node json */
    deserializeDetailPage(input: any): Project {
        this.nid = input.nid[0].value;
        this.title = input.title[0].value;
        this._editLink = "/node/" + input.nid[0].value + "/edit";
        if (input.hasOwnProperty('field_image') && input.field_image.length > 0) {
            this.image = new Image(input.field_image[0].url, input.field_image[0].alt);
        }

        return this;
    }

    getTitle(): string {
        return this.title;
    }

    getPath(): Url {
        return this.path;
    }

    /**
     * Getter image
     * @return {Image}
     */
	public get image(): Image {
		return this._image;
	}

    hasImage(): boolean {
        return this.image ? true : false;
    }

    public getImageUrl() : Url {
        if (this.hasImage()) {
        return this._image.src;
        }
    }

    /**
     * Setter image
     * @param {Image} value
     */
	public set image(value: Image) {
		this._image = value;
	}

    public get editLink(): string {
        return this._editLink;
    }
}
