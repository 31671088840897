import { NewsCategory } from "./news-category.model";
import { Subject } from "rxjs";
import {StoriesService, StoryTypes} from '../services/stories/stories.service';

export class NewsListFilters {
    pageNumber: number = 0;

    possibleYears: any[] = [];
    possibleCategories: NewsCategory[] = [];

    selectedYear: number;
    selectedCategory: NewsCategory;

    filtersChanged = new Subject();

    protected storyType: StoryTypes.News | StoryTypes.Scouts;

    constructor(private storiesService: StoriesService, storyType: StoryTypes.News | StoryTypes.Scouts = StoryTypes.News) {
      this.storyType = storyType;
      this.storiesService.getPossibleYears(this.storyType)
        .subscribe((res: any) => this.possibleYears = [{id: '', label: 'All dates'}].concat(res.map(r=>({id: r, label: r.toString()}))))
      this.storiesService.getPossibleCategories(this.storyType)
        .subscribe((res: any) => this.possibleCategories = [{id: 0, label: 'All categories'}].concat(res))
    }

    setYear(year: number) {
      this.selectedYear = year;
      this.resetPage();
      this.filtersChanged.next(null);
    }

    setCategory(categoryId: number) {
      for (let category of this.possibleCategories) {
        if (category.id == categoryId) {
          this.selectedCategory = category;
        }
      }
      this.resetPage();
      this.filtersChanged.next(null);
    }

    resetPage() {
      this.pageNumber = 0;
    }
  }
