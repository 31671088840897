import { Component, OnInit, Input } from '@angular/core';
import { Image } from '../../shared/models/image.model';

@Component({
  selector: 'drupal-image',
  templateUrl: './drupal-image.component.html',
  styleUrls: ['./drupal-image.component.scss']
})
export class DrupalImageComponent implements OnInit {
  styledImageUrl: string;

  /** The image object itself */
  @Input() image: Image;

  /** Define the image style the image needs to be transformed to, otherwise keeps original src */
  @Input('style') styleName: string = null;

  /** In case this is filled the image will link here */
  @Input('link') url: string;

  /** These classes will be applied on the img tag */
  @Input('additionalClasses') class: string;

  constructor() { }

  ngOnInit() {
  }

  public getStyledImageUrl() {
    if (this.styleName == null) {
      if (this.image.src) {
        return this.image.src;
      }
      else {
        return this.image.url;
      }
    }

    if (this.image.src) {
      this.styledImageUrl = this.image.src.toString();
    }
    else {
      this.styledImageUrl = this.image.url.toString();
    }

    /** In case it is already a styled image */
    if (this.styledImageUrl.indexOf('/sites/default/files/styles/') != -1) {
      this.styledImageUrl = this.styledImageUrl.replace(/(\/styles\/.*\/public\/)(.*)/gi , '/styles/' + this.styleName + '/public/$2');
    }
    /** Otherwise this is an url to the main folder */
    else {
      this.styledImageUrl = this.styledImageUrl.replace('/sites/default/files/', '/sites/default/files/styles/' + this.styleName + '/public/');
    }

    return this.styledImageUrl;
  }
}
