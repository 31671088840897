import { Component, Input, OnInit, DoCheck } from '@angular/core';
import { PaymentRow } from '../../shared/models/form-payment.model';
import { FormGroup } from '@angular/forms';
import { NgbCustomDateParserFormatter } from '../../shared/formatters/ngb-custom-date-parser-formatter';

@Component({
  selector: 'app-form-payment',
  templateUrl: './form-payment.component.html',
  styleUrls: ['./form-payment.component.scss'],
  providers: [
    NgbCustomDateParserFormatter
  ]
})
export class FormPaymentComponent implements OnInit {
  public paymentRows: PaymentRow[] = [];
  public paymentDate = 'payment_date';
  public paymentDateFormControlName = 'paymentDate';
  public paymentAmount = 'payment_amount';
  public paymentAmountFormControlName = 'paymentAmount';
  @Input() group: FormGroup;
  @Input() max = 5;

  constructor(public customDateParserFormatter: NgbCustomDateParserFormatter) { }

  ngOnInit() {
    this.addPaymentRow();
  }

  addPaymentRow() {
    this.paymentRows.push(
      {
        paymentDate: this.paymentDate + '_' + this.paymentRows.length,
        paymentAmount: this.paymentAmount + '_' + this.paymentRows.length,
        paymentDateValue: '',
        paymentAmountValue: '',
      }
    );
  }

  removePaymentRow(index) {
    this.paymentRows.splice(index, 1);
  }

  isShowAddBtn(isLast) {
    return isLast && (this.paymentRows.length < this.max || this.max === -1);
  }

  isShowRemoveBtn(isFirst) {
    return !isFirst;
  }

  getValues() {
    return this.paymentRows;
  }
}
