import { Component, OnInit } from '@angular/core';
import { PagetitleService } from '../../services/page-title/pagetitle.service';
import { HeaderService } from '../../services/header/header.service';
import {ActivatedRoute} from "@angular/router";
import {MediaService} from "../../shared/services/media/media.service";

@Component({
  selector: 'app-mediagallerypage',
  templateUrl: './mediagallerypage.component.html',
  styleUrls: ['./mediagallerypage.component.scss']
})
export class MediagallerypageComponent implements OnInit {

  constructor(private pageTitleService: PagetitleService,
              private headerService: HeaderService,
              private route: ActivatedRoute,
              private mediaService: MediaService) {
    this.pageTitleService.setPageTitle('Media');
    this.pageTitleService.setPrintedTitle('Media');
    this.headerService.setHeaderImage('assets/img/header-img-media-list.jpg');
    this.headerService.shareButtons(true);
  }

  ngOnInit() {
    /*this.route.params
      .subscribe(params => {
        this.pageData = this.mediaService.getStoryData('gallery/' + params['alias']);
      });*/
  }
}
