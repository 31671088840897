import {
  AfterViewInit, Component, ElementRef, OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { BackendApiService } from '../../shared/services/backend-api/backend-api.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormService } from '../../services/form/form.service';
import { BpfGiftComponent } from '../bpf-gift/bpf-gift.component';
import { BpfPledgeComponent } from '../bpf-pledge/bpf-pledge.component';
import { BpfYoungComponent } from '../bpf-young/bpf-young.component';

@Component({
  selector: 'app-baden-powell-fellowship-form',
  templateUrl: './baden-powell-fellowship-form.component.html',
  styleUrls: ['./baden-powell-fellowship-form.component.scss']
})
export class BadenPowellFellowshipFormComponent implements OnInit, AfterViewInit {
  public popupState: PopupState;
  public popupStates = PopupState;
  public popupMessage: string;
  public forms = [
    {
      value: 'bpf-pledge',
      name: 'I want to become a BP Fellow (one person per form)',
    },
    {
      value: 'bpf-young',
      name: 'I want to become a Young BP Fellow (up to the age of 30)',
    },
    {
      value: 'gift',
      name: 'I/we are already BP Fellows and want to give to someone a BP Fellowship',
    }
  ];
  private sendingCurrently: boolean = false;
  public selectedForm: string;
  public badenPowellFellowshipForm: FormGroup;
  public submitAttempt = false;
  public hasError = false;
  public currentComponent: BpfYoungComponent | BpfPledgeComponent | BpfGiftComponent;
  @ViewChild('termsAndConditions') termsAndConditions: TermsAndConditionsComponent;
  @ViewChild('formSelector') formSelector: ElementRef;
  @ViewChild('bpfPledge') bpfPledge: BpfPledgeComponent;
  @ViewChild('bpfYoung') bpfYoung: BpfYoungComponent;
  @ViewChild('bpfGift') bpfGift: BpfGiftComponent;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private backendApiService: BackendApiService,
              public formBuilder: FormBuilder,
              public ngxSmartModalService: NgxSmartModalService,
              public formService: FormService) {
    this.route.params.subscribe((params: any) => {
      if (params.hasOwnProperty('alias')) {
        this.selectedForm = params.alias;
        this.createForm();
        this.reset();
      }
    });

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.route.params.subscribe((params: any) => {
      setTimeout(() => {
        this.currentComponent = (this.selectedForm === 'bpf-pledge')
          ? this.bpfPledge
          : ((this.selectedForm === 'bpf-young')
            ? this.bpfYoung
            : this.bpfGift);
      });
    });
  }

  public formSelectorChange(): void {
    this.router.navigate(['/bp-fellowship', this.formSelector.nativeElement.value]);
  }

  protected createForm() {
    let fields = this.getFields();

    if (fields) {
      this.badenPowellFellowshipForm = this.formBuilder.group(fields);
    }
  }

  private getPostData() {
    let fields = this.getFields(),
      data = {
        selectedForm: this.selectedForm,
      };

    if (fields) {
      for (let i in fields) {
        if (i === 'paymentDate' || i === 'paymentAmount') {
          continue;
        }

        if (i === 'captcha') {
          data[i] = this.termsAndConditions.captchaValue;
        }
        else {
          data[i] = this.badenPowellFellowshipForm.get(i).value;
        }
      }

      data['payment'] = this.currentComponent.formPayment.getValues();
    }

    return data;
  }

  public submit(): void {
    // Indicate we tried to submit once!
    this.submitAttempt = true;
    // Validate before submit.
    // todo separate later validate and submit.
    if (this.badenPowellFellowshipForm.valid && this.termsAndConditions.captchaSuccessful && !this.sendingCurrently) {
      this.backendApiService.setRestEndpoint();
      this.sendingCurrently = true;
      this.hasError = false;

      this.backendApiService.post('bpf_form/submit', this.getPostData()).subscribe((resp: any) => {
        this.popupState = PopupState.Passed;
        if (resp.success === true) {
          this.popupMessage = resp.message;
          this.ngxSmartModalService.getModal('badenPowellFellowshipModal').open();
          // Reset back after submission.
          this.reset();
          this.sendingCurrently = false;
        }
      }, (err) => {
        this.submitAttempt = false;
        this.sendingCurrently = false;
        this.popupState = PopupState.Failed;
        this.popupMessage = err.error.message;
        this.ngxSmartModalService.getModal('badenPowellFellowshipModal').open();
      });
    } else {
      this.hasError = true;
    }
  }

  // Resets the form and submit attempt.
  public reset() {
    this.badenPowellFellowshipForm.reset();
    if (this.termsAndConditions) {
      this.termsAndConditions.reCaptcha.reset();
    }
    this.submitAttempt = false;
  }

  public closeModal(): void {
    this.ngxSmartModalService.getModal('badenPowellFellowshipModal').close();
  }

  public print(ev): void {
    ev.preventDefault();

    this.badenPowellFellowshipForm.get('signature').disable({emitEvent: true});

    Object.keys(this.badenPowellFellowshipForm.controls).forEach(field => {
      const control = this.badenPowellFellowshipForm.get(field);
      control.markAsTouched();
      control.updateValueAndValidity({emitEvent: true});
    });

    if (this.badenPowellFellowshipForm.valid) {
      this.formService.print();

      this.reset();
    }
  }

  private getFields(): Object {
    let fields;

    switch (this.selectedForm) {
      case 'bpf-pledge':
      case 'bpf-young':
        fields = {
          firstName: ['', [Validators.required]],
          lastName: ['', [Validators.required]],
          dobDay: ['', [Validators.required]],
          dobMonth: ['', [Validators.required]],
          dobYear: ['', [Validators.required]],
          city: ['', [Validators.required]],
          street: ['', [Validators.required]],
          followingAddress: ['', [Validators.required]],
          postalCode: ['', [Validators.required]],
          country: ['', [Validators.required]],
          tel: ['', [Validators.required]],
          email: ['', [Validators.required, Validators.email]],
          registerMeUnder: ['', [Validators.required]],
          howToList: ['', [Validators.required]],
          paymentDate: ['', [Validators.required]],
          paymentAmount: ['', [Validators.required]],
          date: ['', [Validators.required]],
          signature: ['', [Validators.required]],
          termsAndConditions: ['', [Validators.required]],
          captcha: [''],
        };
      break;
      case 'gift':
        fields = {
          recipientFirstName: ['', [Validators.required]],
          recipientLastName: ['', [Validators.required]],
          recipientDobDay: ['', [Validators.required]],
          recipientDobMonth: ['', [Validators.required]],
          recipientDobYear: ['', [Validators.required]],
          recipientCity: ['', [Validators.required]],
          recipientStreet: ['', [Validators.required]],
          recipientPostalCode: ['', [Validators.required]],
          recipientCountry: ['', [Validators.required]],
          recipientTel: ['', [Validators.required]],
          recipientEmail: ['', [Validators.required, Validators.email]],
          recipientRelation: ['', [Validators.required]],
          registerRecipientUnder: ['', [Validators.required]],
          firstName: ['', [Validators.required]],
          lastName: ['', [Validators.required]],
          dobDay: ['', [Validators.required]],
          dobMonth: ['', [Validators.required]],
          dobYear: ['', [Validators.required]],
          city: ['', [Validators.required]],
          street: ['', [Validators.required]],
          followingAddress: ['', [Validators.required]],
          postalCode: ['', [Validators.required]],
          country: ['', [Validators.required]],
          tel: ['', [Validators.required]],
          email: ['', [Validators.required, Validators.email]],
          howToList: ['', [Validators.required]],
          paymentDate: ['', [Validators.required]],
          paymentAmount: ['', [Validators.required]],
          date: ['', [Validators.required]],
          signature: ['', [Validators.required]],
          termsAndConditions: ['', [Validators.required]],
          captcha: [''],
        };
      break;
    }

    return fields;
  }
}

export enum PopupState {
  Passed,
  Failed
}
