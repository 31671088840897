export class NewsLetterData {
  protected email: string;
  protected firstName: string;
  protected lastName: string;
  protected country: string;
  protected captcha: string;

  public constructor(
    email: string,
    firstName: string,
    lastName: string,
    country: string,
    captcha: string
  ) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.country = country;
    this.captcha = captcha;
  }
}
