import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {BreadCrumbItem} from "../../models/breadcrumb-item.model";

@Injectable()
export class BreadcrumbService {
  public breadCrumb = new Subject<BreadCrumbItem[]>();

  constructor() {
  }

  setBreadCrumb(breadCrumbs: BreadCrumbItem[]) {
    this.breadCrumb.next(breadCrumbs);
  }
}