import { BackendApiService } from './../services/backend-api/backend-api.service';
import { environment } from './../../../environments/environment';
import { Directive, ElementRef, Input, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { BlockAdminService } from '../services/block-admin/block-admin.service';
import { SubscriptionLike as ISubscription } from 'rxjs';

// Directive decorator
@Directive({
  selector: '[blockAdmin]',
  host: {
    '(mouseenter)': 'showAdminPanel()',
    '[class.block-admin]': 'true'
  }
})
// Directive class
export class BlockAdminDirective implements OnInit, OnDestroy {
  @Input('blockAdmin') set linksConfig(value) {
    this._linksConfig = value;
    if (this.listEl) {
      this.init();
    }
  }
  get linksConfig() {
    return this._linksConfig;
  }
  private _linksConfig: any;
  private subscription: ISubscription;
  private isAdmin: boolean = null;
  private listEl;
  private buttonListener: () => void;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private blockAdminService: BlockAdminService,
    private api: BackendApiService
  ) {
    this.isAdmin = api.isAdmin.getValue();
    api.isAdmin.subscribe((res: boolean) => this.isAdmin = res);
  }

  ngOnInit() {
    this.listEl = document.createElement('ul');
    this.listEl.className = 'admin-cog';
    this.el.nativeElement.append(this.listEl);
    this.init();

    // Disable admin features for current directive if another is active
    this.subscription = this.blockAdminService.adminPanelOpened.subscribe((element: ElementRef) => {
      if (element !== this.el) {
        this.hideAdminPanel();
      }
    });
  }

  private init() {
    this.clearList();
    if (this.buttonListener) {
      this.buttonListener();
    }

    Object.keys(this.linksConfig).forEach((key) => {
      // Construct and append the edit button to the host
      const li = document.createElement('li');
      li.innerText = key;
      li.setAttribute('admin-url', this.linksConfig[key]);
      this.listEl.append(li);

      // Add click event to the cog
      const button = this.el.nativeElement.querySelector('.admin-cog');
      this.buttonListener = this.renderer.listen(button, 'click', (event) => {
        this.goToAdmin(event.target);
      });
    });
  }

  private clearList() {
    Array.from(this.listEl.children).forEach(child => {
      this.renderer.removeChild(this.listEl, child);
    });
  }

  goToAdmin(el) {
    window.open(environment.baseURL + el.getAttribute('admin-url'), 'admin');
  }

  showAdminPanel() {
    // todo: we should not even add / init classes and fields, so this is just a quick n dirty
    if (!this.isAdmin) {
      return;
    }
    this.renderer.addClass(this.el.nativeElement, 'block-admin-hover');
    this.blockAdminService.adminPanelOpen(this.el);
  }

  hideAdminPanel() {
    this.renderer.removeClass(this.el.nativeElement, 'block-admin-hover');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

class AdminLink {
  name: string;
  url: string;

  constructor(name: string, url: string) {
    this.name = name;
    this.url = url;
  }
}
