import { BehaviorSubject } from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class HeaderService {
  public shareButtonVisibility: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {
  }

  public setHeaderImage(src: string) {
    return src;
  }

  shareButtons(visible: boolean) {
    this.shareButtonVisibility.next(visible);
  }
}
