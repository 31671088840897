import { Observable } from 'rxjs';
import { MediaService } from './../../shared/services/media/media.service';
import { EventsService } from './../../shared/services/events/events.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Media } from '../../shared/models/media.model';
import { Event } from '../../shared/models/event.model';
import { VideoModalComponent } from '../video-modal/video-modal.component';

@Component({
  selector: 'app-events-latest-media',
  templateUrl: './events-latest-media.component.html',
  styleUrls: ['./events-latest-media.component.scss']
})
export class EventsLatestMediaComponent implements OnInit {
  nextEvent$: Observable<Event> = new Observable;
  latestMedia$: Observable<Media> = new Observable;
  @ViewChild(VideoModalComponent) videoModalComponent;

  constructor(private eventsService: EventsService, private mediaService: MediaService) { }

  ngOnInit() {
    this.nextEvent$ = this.eventsService.getNextEvent();
    this.latestMedia$ = this.mediaService.getLatestMedia();
  }

  public openVideoModal(item): void {
    if (item.getVideourl()) {
      this.videoModalComponent.open(item);
    }
  }
}
