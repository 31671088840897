import { Component, OnInit } from '@angular/core';
import { BlockHandlerService } from '../../shared/services/block-handler/block-handler.service';
import { Block } from '../../shared/models/block.model';

@Component({
  selector: 'app-scouts',
  templateUrl: './scouts.component.html',
  styleUrls: ['./scouts.component.scss']
})
export class ScoutsComponent implements OnInit {
  scoutsBlock: Block;

  constructor(private blockHandlerService: BlockHandlerService) { }

  ngOnInit() {
    this.blockHandlerService.getBlockData('home-scouts-org')
    .subscribe((res: any) => this.scoutsBlock = res);
  }

}
