import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../services/form/form.service';
import { PagetitleService } from '../../services/page-title/pagetitle.service';
import { FormPaymentComponent } from '../form-payment/form-payment.component';

@Component({
  selector: 'app-bpf-pledge',
  templateUrl: './bpf-pledge.component.html',
  styleUrls: ['./bpf-pledge.component.scss']
})
export class BpfPledgeComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() formService: FormService;
  @ViewChild('formPayment') formPayment: FormPaymentComponent;

  public pageTitle = 'The World Baden-Powell Fellowship';

  constructor(private pageTitleService: PagetitleService) {
      this.pageTitleService.setPageTitle(this.pageTitle).setPrintedTitle(this.pageTitle);
  }

  ngOnInit() {
  }

}
