import {Injectable} from '@angular/core';

@Injectable()
// todo finish this does not work yet.
export class BodyClassService {

    protected static classes: object[] = [];

    constructor() {
    }

    public static getClasses() {
        return BodyClassService.classes;
    }

    public static addToRoute(route: string, cssClass: string) {
        if (!BodyClassService.classes.hasOwnProperty(route)) {
            BodyClassService.classes[route] = [];
        }
        BodyClassService.classes[route].push(cssClass);
    }
}
