import {Component, OnInit, Input} from '@angular/core';
import {EventsService} from "../../shared/services/events/events.service";
import {Event} from '../../shared/models/event.model';
import { PagetitleService } from '../../services/page-title/pagetitle.service';
import { HeaderService } from '../../services/header/header.service';
import { BreadcrumbService } from '../../shared/services/breadcrumb/breadcrumb.service';
import { BreadCrumbItem } from '../../shared/models/breadcrumb-item.model';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit {
  events: Event[] = [];
  public eventFilters = {
    pageNumber: 0
  };
  loadMoreEnabled: boolean = true;
  eventListPager: number[];

  constructor(
    private eventService: EventsService,
  ) { }

  ngOnInit() {
    this.loadEvents();
  }

  loadEvents(): void {
    this.eventService.getEvents(this.eventFilters)
      .subscribe(res => {
        // Add array of next events to the current array of events
        Array.prototype.push.apply(this.events, res);

        // Get pager data from service
        this.eventListPager = this.eventService.getEventListPager();

        // Disabling load more button if there are no more pages to load
        if ((this.eventListPager[1] - 1) === this.eventListPager[0]) {
          this.loadMoreEnabled = false;
        }
      });
  }

  loadMore(): void {
    this.eventFilters.pageNumber++;
    this.loadEvents();
  }
}
