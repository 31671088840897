import { Component, OnInit } from '@angular/core';
import { PagetitleService } from '../../services/page-title/pagetitle.service';
import { HeaderService } from '../../services/header/header.service';
import {BreadcrumbService} from "../../shared/services/breadcrumb/breadcrumb.service";
import {BreadCrumbItem} from "../../shared/models/breadcrumb-item.model";

@Component({
  selector: 'app-newspage',
  templateUrl: './newspage.component.html',
  styleUrls: ['./newspage.component.scss']
})
export class NewspageComponent implements OnInit {

  constructor(private pageTitleService: PagetitleService,
              private headerService: HeaderService,
              private breadCrumbService: BreadcrumbService) {
    this.pageTitleService.setPageTitle('News');
    this.pageTitleService.setPrintedTitle('News');
    this.headerService.setHeaderImage('assets/img/header-img-news.jpg');
    this.headerService.shareButtons(false);
  }

  ngOnInit() {
    this.breadCrumbService.setBreadCrumb([new BreadCrumbItem('News')]);
  }
}
