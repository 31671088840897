import { Component, OnInit } from '@angular/core';
import { PagetitleService } from '../../services/page-title/pagetitle.service';
import { HeaderService } from '../../services/header/header.service';
import {BreadCrumbItem} from "../../shared/models/breadcrumb-item.model";
import {BreadcrumbService} from "../../shared/services/breadcrumb/breadcrumb.service";

@Component({
  selector: 'app-donatepage',
  templateUrl: './donatepage.component.html',
  styleUrls: ['./donatepage.component.scss']
})
export class DonatepageComponent implements OnInit {

  constructor(private pageTitleService: PagetitleService,
              private headerService: HeaderService,
              private breadCrumbService: BreadcrumbService) {
    this.pageTitleService.setPageTitle('Donate');
    this.pageTitleService.setPrintedTitle('Donate');
    this.headerService.setHeaderImage('assets/img/header-img-donate.jpg');
    this.headerService.shareButtons(true);
  }

  ngOnInit() {
    this.breadCrumbService.setBreadCrumb([new BreadCrumbItem('Donate')]);
  }
}
