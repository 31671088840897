import { Title } from '@angular/platform-browser';
import { Time } from "@angular/common";
import { Url } from "url";
import { Deserializable } from "./deserializable.model";
import * as moment from 'moment';
import { Paragraph } from './paragraph.model';
import { ContentWithParagraphs } from './content-with-paragraphs.model';
import { Image } from './image.model';

export class Story extends ContentWithParagraphs implements Deserializable<Story> {
    private title: string;
    private _summary: string;
    path: string;
    date: moment.Moment;
    private categories: string[] = [];
    private _image: Image;
    private _editLink: string;
    public fieldFiles: BlockFiles[] = [];

    /** Deserializes data coming from our own API with only needed data */
    deserialize(input: any): Story {
        Object.assign(this, input);

        this._editLink = input.edit_link;
        this.date = moment(input.date);
        if (input.summary) {
            this._summary = input.summary;
        }
        if (input.hasOwnProperty('category') && input.category != null) {
            this.categories = input.category;
        }
        if (input.hasOwnProperty('image') && input.image != null) {
            this.image = new Image(input.image.url, input.image.alt);
        }

        return this;
    }

    /** Helps to deserialize the data coming from a full node json */
    deserializeDetailPage(input: any): Story {
        if (input.hasOwnProperty('categories') && input.categories != null) {
           this.categories = input.categories;
        }
        this.title = input.title[0].value;
        this._editLink = "/node/" + input.nid[0].value + "/edit";
        if (input.hasOwnProperty('field_image') && input.field_image.length > 0) {
            this.image = new Image(input.field_image[0].url, input.field_image[0].alt);
        }
        if (input.hasOwnProperty('field_date')) {
            this.date = moment(input.field_date[0].value);
        }

        for(let i = 0; i < input.field_files.length; i++){
        this.fieldFiles.push(
          {
            url: input.field_files[i].url,
            description: input.field_files[i].description,
            cleanUrl: /[^/]*$/.exec(decodeURI(input.field_files[i].url))[0]
          });
        }

        return this;
    }

    getTitle() {
        return this.title;
    }

    getMainCategory() {
        return Object.keys(this.categories).length > 0 ? this.categories[0] : '';
    }

    getUrl(): string {
        return this.path;
    }

    getDate(): string {
        return this.date.format('DD MMM YYYY');
    }

    getCategories(): string[] {
        return this.categories;
    }

    getCategoryString(): string {
        return this.categories.join(', ');
    }

    /**
     * Getter image
     * @return {Image}
     */
	public get image(): Image {
		return this._image;
	}

    /**
     * Setter image
     * @param {Image} value
     */
	public set image(value: Image) {
		this._image = value;
	}

    /**
     * Getter summary
     * @return {string}
     */
	public get summary(): string {
		return this._summary;
	}

    /**
     * Setter summary
     * @param {string} value
     */
	public set summary(value: string) {
		this._summary = value;
	}

    /**
     * Getter editLink
     * @return {string}
     */
	public get editLink(): string {
		return this._editLink;
	}

    /**
     * Returns a modified summary for output.
     * @return {string}
     */
	public getModifiedSummary(): string {
	    return this._summary + '...';
    }
}