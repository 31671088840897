import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Injectable()
export class BodyClassListenerService{
    protected path: string;
    protected renderer: Renderer2;
    public previousUrl: string;

    constructor(private rendererFactory: RendererFactory2, private router: Router) {
        // You cannot instantiate from the renderer alone.
        // Also make sure we bind it to the class.
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    // Simply attaches the router's name as body class.
    public attach() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    if (this.previousUrl) {
                        this.renderer.removeClass(document.body, this.previousUrl);
                    }
                    // Special case for home.
                    const route = event.url === '/' ? '/home' : event.url;
                    // Get rid of /
                    const currentUrlSlug = route.slice(1);
                    if (currentUrlSlug) {
                        this.renderer.addClass(document.body, currentUrlSlug);
                    }
                    this.previousUrl = currentUrlSlug;
                }
            });
    }

}
