import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/materialize';
import 'rxjs/add/operator/dematerialize';
import 'rxjs/add/operator/delay';
import 'rxjs/add/observable/of';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // wrap in delayed observable to simulate server api call
    return Observable.of(null).mergeMap(() => {
      if (request.url.startsWith('/rest/v1/blocks/') && request.method === 'GET') {
        let urlParts = request.url.split('/');
        let blockName = urlParts[4];

        let body = {};

        if (blockName == 'home-about-image') {
          body = {
            "version": "v1",
            "title": "",
            "data": [
              {
                "type": "site_banner_and_info_area",
                "title": "",
                "description": "",
                "changed": "2018-03-19T15:36:51+0000",
                "id": "4",
                "uuid": "59e5035a-12e9-4eb2-bd41-8425cf38fe0d",
                "image": {
                  "url": "assets/img/mock/about-wsf.jpg",
                  "alt": "WSF home"
                },
                "link": {
                  "url": "/",
                  "text": "more"
                },
                "slides": null
              }
            ]
          };
        }

        if (blockName == 'home-about-left') {
          body = {
            "version": "v1",
            "title": "",
            "data": [
              {
                "type": "site_banner_and_info_area",
                "title": "About the WSF",
                "description": "<p>Our 2016 Annual Report features a private insight to a very public man – His Majesty the King of Sweden opens up his home and his heart to the World Scout Foundation network, explaining His personal belief in Scouting, His values and His hopes for the future.Read more to find out how a boy of 11 overcame his fears to help inspire millions of others to do amazing things.</p>",
                "changed": "2018-03-19T15:36:51+0000",
                "id": "4",
                "uuid": "59e5035a-12e9-4eb2-bd41-8425cf38fe0d",
                "image": {
                  "url": "http://local.wsf.com/sites/default/files/2018-03/001b0000005iui6aak-logo-1516015744_1_3.png",
                  "alt": "alt"
                },
                "link": {
                  "url": "/",
                  "text": "more"
                },
                "slides": null
              }
            ]
          };
        }

        if (blockName == 'home-about-right') {
          body = {
            "version": "v1",
            "title": "",
            "data": [
              {
                "type": "site_banner_and_info_area",
                "title": "",
                "description": "<b>Support</b><br>World Scout Foundation",
                "changed": "2018-03-19T15:36:51+0000",
                "id": "4",
                "uuid": "59e5035a-12e9-4eb2-bd41-8425cf38fe0d",
                "image": {
                  "url": "http://local.wsf.com/sites/default/files/2018-03/001b0000005iui6aak-logo-1516015744_1_3.png",
                  "alt": "alt"
                },
                "link": {
                  "url": "/",
                  "text": "more"
                },
                "slides": null
              }
            ]
          };
        }

        if (blockName == 'home-scouts-org') {
          body = {
            "version": "v1",
            "title": "",
            "data": [
              {
                "type": "site_banner_and_info_area",
                "title": "Scouts.org",
                "description": "The Worlds Leading <b>Educational</b> Youth Movement",
                "changed": "2018-03-19T15:36:51+0000",
                "id": "4",
                "uuid": "59e5035a-12e9-4eb2-bd41-8425cf38fe0d",
                "image": {
                  "url": "assets/img/mock/scouts-org.jpg",
                  "alt": "alt"
                },
                "link": {
                  "url": "/",
                  "text": "Visit page"
                },
                "slides": null
              }
            ]
          };
        }

        if (blockName == 'footer-left') {
          body = {
            "version": "v1",
            "title": "",
            "data": [
              {
                "type": "site_banner_and_info_area",
                "title": "World Scout Foundation",
                "description": "1 rue de la Naviagtion<br>PO Box 2116 1211 Geneva 1<br>Switzerland<br>Phone +41 22 705 10 90<br>Mail <a href='mailto:info@worldscoutfoundation.org'>info@worldscoudfoundation.org</a>",
                "changed": "2018-03-19T15:36:51+0000",
                "id": "4",
                "uuid": "59e5035a-12e9-4eb2-bd41-8425cf38fe0d",
                "image": {
                  "url": "assets/img/mock/scouts-org.jpg",
                  "alt": "alt"
                },
                "link": {
                  "url": "/",
                  "text": "Visit page"
                },
                "slides": null
              }
            ]
          };
        }

        return Observable.of(new HttpResponse({ status: 200, body: body }));
      }

      if (request.url.startsWith('/rest/v1/events/home') && request.method === 'GET') {
        let body = {
          "version": "v1",
          "title": "",
          "data": [
            {
              "title": "71st Fellowship Event & Investors Conference",
              "date_range": "2016-01-01T12:00:00 - 2016-02-02T12:00:00",
              "image": {
                "url": "assets/img/mock/events-latest-media-1.jpg",
                "alt": "image"
              },
              "path": "/event/event",
              "country": "Albania"
            }
          ]
        }
        return Observable.of(new HttpResponse({status: 200, body: body}));
      }

      if (request.url.startsWith('/rest/v1/media/home') && request.method === 'GET') {
        let body = {
          "version": "v1",
          "title": "Latest Media",
          "data": [
            {
              "title": "71st Fellowship Event & Investors Conference",
              "video": "https://www.youtube.com/watch?v=dWZPgDznows",
              "image": {
                "url": "assets/img/mock/events-latest-media-2.jpg",
                "alt": "image"
              },
              "images": [],
              "path": "/content/video"
            }
          ]
        }
        return Observable.of(new HttpResponse({status: 200, body: body}));
      }

      if (request.url.startsWith('/rest/v1/events/list') && request.method === 'GET') {
        let body = {
          "version": "v1",
          "title": "",
          "data": [
            {
              "title": "Join the Scout Heroes of the Earthquake Disaster in Mexico!",
              "date_range": "2016-01-01T12:00:00 - 2016-02-02T12:00:00",
              "image": {
                "url": "assets/img/events-list-1.jpg",
                "alt": "event image"
              },
              "path": "/event/event",
              "country": "SWITZERLAND",
              "city": "GENEVE"
            },
            {
              "title": "Welcoming refugees - In Sweden it's just called Scouting",
              "date_range": "2016-01-01T12:00:00 - 2016-02-02T12:00:00",
              "image": {
                "url": "assets/img/events-list-2.jpg",
                "alt": "event image"
              },
              "path": "/event/event",
              "country": "FRANCE",
              "city": "PARIS"
            },
            {
              "title": "Join the Scout Heroes of the Earthquake Disaster in Mexico!",
              "date_range": "2016-01-01T12:00:00 - 2016-02-02T12:00:00",
              "image": {
                "url": "assets/img/events-list-3.jpg",
                "alt": "event image"
              },
              "path": "/event/event",
              "country": "GERMANY",
              "city": "BERLIN"
            },
            {
              "title": "Example event",
              "date_range": "2016-01-01T12:00:00",
              "image": {
                "url": "assets/img/events-list-1.jpg",
                "alt": "event image"
              },
              "path": "/event/event",
              "country": "Country",
              "city": "City"
            },
          ],
          "pager": {
            "count": "10",
            "pages": 1,
            "items_per_page": 3,
            "current_page": 0
          }
        };
        return Observable.of(new HttpResponse({status: 200, body: body}));
      }

      if (request.url.startsWith('/rest/v1/scout-news/home') && request.method === 'GET') {
        let body =  {
          "version": "v1",
          "title": "",
          "data": [
            {
              "title": "Engaging and active Scouting Czech",
              "description": "Our 2016 Annual Report features a private insight to a very public man – His Majesty the King of Sweden opens up his home and his heart to the World Scout Foundation network, explaining His personal belief in Scouting, His values and His hopes for the future. Read more to find out how a boy of 11 overcame his fears to help inspire millions of others to do amazing things.",
              "paragraphs": [
                "7",
                "8",
                "9"
              ],
              "categories": [
                "WSF",
                "MOP"
              ],
              "date": "2018-01-01T01:00:00",
              "path": "/content/scout-story"
            },
            {
              "title": "WSF Annual Report 2016",
              "description": "Our 2016 Annual Report features a private insight to a very public man – His Majesty the King of Sweden opens up his home and his heart to the World Scout Foundation network, explaining His personal belief in Scouting, His values and His hopes for the future. Read more to find out how a boy of 11 overcame his fears to help inspire millions of others to do amazing things.",
              "paragraphs": [
                "7",
                "8",
                "9"
              ],
              "categories": [
                "MOP"
              ],
              "date": "2018-01-01T01:00:00",
              "path": "/content/scout-story"
            },
            {
              "title": "70th Baden-Powell Fellowship Event",
              "description": "Our 2016 Annual Report features a private insight to a very public man – His Majesty the King of Sweden opens up his home and his heart to the World Scout Foundation network, explaining His personal belief in Scouting, His values and His hopes for the future. Read more to find out how a boy of 11 overcame his fears to help inspire millions of others to do amazing things.",
              "paragraphs": [
                "7",
                "8",
                "9"
              ],
              "categories": [
                "WSF"
              ],
              "date": "2018-01-01T01:00:00",
              "path": "/content/scout-story"
            }
          ]
        }
        return Observable.of(new HttpResponse({ status: 200, body: body }));
      }

      if (request.url.startsWith('/rest/v1/news/list') && request.method === 'GET') {
        let body =  {
          "version": "v1",
          "title": "",
          "data": [
            {
              "title": "Engaging and active Scouting Czech",
              "image": {
                "url": "assets/img/mock/news-list-1.jpg",
                "alt": "image"
              },
              "description": "Our 2016 Annual Report features a private insight to a very public man – His Majesty the King of Sweden opens up his home and his heart to the World Scout Foundation network, explaining His personal belief in Scouting, His values and His hopes for the future. Read more to find out how a boy of 11 overcame his fears to help inspire millions of others to do amazing things.",
              "categories": [
                "WSF",
                "MOP"
              ],
              "date": "2018-01-01T01:00:00",
              "path": "/content/scout-story"
            },
            {
              "title": "WSF Annual Report 2016",
              "image": {
                "url": "assets/img/mock/news-list-2.jpg",
                "alt": "image"
              },
              "description": "Our 2016 Annual Report features a private insight to a very public man – His Majesty the King of Sweden opens up his home and his heart to the World Scout Foundation network, explaining His personal belief in Scouting, His values and His hopes for the future. Read more to find out how a boy of 11 overcame his fears to help inspire millions of others to do amazing things.",
              "categories": [
                "MOP"
              ],
              "date": "2018-01-01T01:00:00",
              "path": "/content/scout-story"
            },
            {
              "title": "70th Baden-Powell Fellowship Event",
              "image": {
                "url": "assets/img/mock/news-list-3.jpg",
                "alt": "image"
              },
              "description": "Our 2016 Annual Report features a private insight to a very public man – His Majesty the King of Sweden opens up his home and his heart to the World Scout Foundation network, explaining His personal belief in Scouting, His values and His hopes for the future. Read more to find out how a boy of 11 overcame his fears to help inspire millions of others to do amazing things.",
              "categories": [
                "WSF"
              ],
              "date": "2018-01-01T01:00:00",
              "path": "/content/scout-story"
            }
          ]
        }
        return Observable.of(new HttpResponse({ status: 200, body: body }));
      }

      if (request.url.startsWith('/rest/v1/social-counter') && request.method === 'GET') {
        let body =    {
          "version": "v1",
          "title": "",
          "data": [
            {
              "type": "facebook",
              "counter": "100"
            },
            {
              "type": "twitter",
              "counter": "200"
            },
            {
              "type": "youtube",
              "counter": "300"
            }
          ]
        }
        return Observable.of(new HttpResponse({ status: 200, body: body }));
      }

      // pass through any requests not handled above
      return next.handle(request);
    })

    // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      .materialize()
      .delay(500)
      .dematerialize();
  }
}

export let FakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};
