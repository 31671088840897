import { Component, OnInit, Input } from '@angular/core';
import { ParagraphService } from '../../shared/services/paragraph/paragraph.service';
import { Paragraph } from '../../shared/models/paragraph.model';
import { SidebarTemplate } from '../../shared/models/sidebar-template.model';

@Component({
  selector: 'app-sidebar-template',
  templateUrl: './sidebar-template.component.html',
  styleUrls: ['./sidebar-template.component.scss']
})
export class SidebarTemplateComponent implements OnInit {
  @Input() set sidebar(value) {
    this.paragraphs = null;

    if (value) {
      this._sidebar = value;
      this.initSidebar();
    }
  }

  public _sidebar: SidebarTemplate;
  public paragraphs: Paragraph[];

  constructor(
    private paragraphService: ParagraphService
  ) { }

  ngOnInit() {
    if (!this._sidebar) { return; }
  }

  private initSidebar() {
    if (this._sidebar.paragraphs && this._sidebar.paragraphs.length) {
      const idList: number[] = [];
      this._sidebar.paragraphs.forEach(item => {
        idList.push(item.target_id);
      });

      this.paragraphService.getParagraphs(idList).subscribe(res => {
        this.paragraphs = res;
      });
    }
  }

}
