export class Donate {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  donationType: string;
  donationAmount: number;
  donationFrequency: number;
  project: string;
  purpose: string;
  captcha: string;
  termsAndConditions: boolean;

  public constructor(
    firstName: string,
    lastName: string,
    email: string,
    country: string,
    donationType: string,
    donationAmount: number,
    donationFrequency: number,
    project: string,
    purpose: string,
    termsAndConditions: boolean,
    captcha: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.country = country;
    this.donationType = donationType;
    this.donationAmount = donationAmount;
    this.donationFrequency = donationFrequency;
    this.project = project;
    this.purpose = purpose;
    this.captcha = captcha;
    this.termsAndConditions = termsAndConditions;
  }
}
