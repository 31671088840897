import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { NguCarousel } from '@ngu/carousel';
import { BlockHandlerService } from '../../shared/services/block-handler/block-handler.service';
import { Block } from '../../shared/models/block.model';

@Component({
  selector: 'app-news-highlighted',
  templateUrl: './news-highlighted.component.html',
  styleUrls: ['./news-highlighted.component.scss']
})
export class NewsHighlightedComponent implements OnInit {
  slidesBlock: Observable<Block> = new Observable();
  carouselOne: NguCarousel;

  constructor(private blockHandlerService: BlockHandlerService) { }

  ngOnInit() {
    this.slidesBlock = this.blockHandlerService.getBlockData('news-slider');

    this.carouselOne = {
      grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
      slide: 1,
      speed: 400,
      interval: 1114000,
      point: {
        visible: true
      },
      load: 1,
      touch: true,
      loop: true
    };
  }

}
