import { NewsCategory } from './../../models/news-category.model';
import { Injectable } from '@angular/core';
import { BackendApiService } from '../backend-api/backend-api.service';
import { Story } from '../../models/story.model';
import {Observable,  Subject } from 'rxjs';
import { NewsListFilters } from '../../models/news-filters.model';
import { ParagraphService } from '../paragraph/paragraph.service';

@Injectable()
export class StoriesService {
  storyTypes: StoryTypes;

  constructor(private paragraphService: ParagraphService, private backendApiService: BackendApiService) { }

  /** Getting information for the detail page */
  getStoryData(machineName: string): Observable<Story> {
    this.backendApiService.setNodeEndpoint();
    return this.backendApiService.get(machineName)
      .map((res: any) => {
        let page = new Story().deserializeDetailPage(res);

        let paragraphIds:number[] = [];
        for (let paragraph of res.field_paragraphs) {
          paragraphIds.push(paragraph.target_id);
        }

        this.paragraphService.getParagraphs(paragraphIds)
        .subscribe((res: any) => {
          page.setParagraphs(res);
        });

        return page;
      });
  }

  /** This function is getting the 3 latest stories for the main page block */
  getMainPageStories(): Observable<Story[]> {
    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('news/home')
      .map((res: any) => {
        let stories: Story[] = [];
        for (let storyIndex in res.data) {
          stories.push(new Story().deserialize(res.data[storyIndex]));
        }
        return stories;
      });
  }

  /** This function is getting the latest news for the news list page */
  getLatestNews(filters: NewsListFilters, storyType: StoryTypes): Observable<{stories, pager}> {
    let params: {page?: number, category?: number, 'date[min]'?: string, 'date[max]'?: string} = {};
    if (filters.pageNumber > 0) {
      params.page = filters.pageNumber;
    }

    if (filters.selectedCategory && filters.selectedCategory.id > 0) {
      params.category = filters.selectedCategory.id;
    }

    if (filters.selectedYear) {
      params['date[min]'] = filters.selectedYear + '-01-01';
      params['date[max]'] = filters.selectedYear + '-12-31';
    }

    this.backendApiService.setRestEndpoint();
    let endpoint = storyType == StoryTypes.News ? 'news/list' : 'scout-news/list';
    return this.backendApiService.get(endpoint, params)
      .map((res: any) => {
        let stories: Story[] = [];
        for (let storyIndex in res.data) {
          stories.push(new Story().deserialize(res.data[storyIndex]));
        }
        return {
          stories: stories,
          pager: res.pager
        };
      });
  }

  /** This function is getting possible years for stories */
  getPossibleYears(storyType: StoryTypes.News | StoryTypes.Scouts): Observable<number[]> {
    this.backendApiService.setRestEndpoint();
    let endpoint = storyType === StoryTypes.News ? 'news/filter/years' : 'scout-news/filter/years';
    return this.backendApiService.get(endpoint, {})
      .map((res: any) => {
        let years: number[] = [];
        for (let storyIndex in res.data) {
          years.push(res.data[storyIndex]);
        }
        return years.reverse();
      });
  }

  /** This function is getting possible categories for stories */
  getPossibleCategories(storyType: StoryTypes.News | StoryTypes.Scouts): Observable<NewsCategory[]> {
    this.backendApiService.setRestEndpoint();
    let endpoint = storyType === StoryTypes.News ? 'news/filter/categories': 'scout-news/filter/categories';
    return this.backendApiService.get(endpoint, {})
      .map((res: any) => {
        let categories: NewsCategory[] = [];
        for (let storyIndex in res.data) {
          categories.push(new NewsCategory(res.data[storyIndex].id, res.data[storyIndex].name));
        }
        return categories;
      });
  }

  getNewsByProject(nid: number) {
    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('news/list-big', { project: nid })
      .map((res: any) => {
        let stories: Story[] = [];
        for (let storyIndex in res.data) {
          stories.push(new Story().deserialize(res.data[storyIndex]));
        }
        return stories;
      });
  }
}

export enum StoryTypes {
  News,
  Scouts
}
