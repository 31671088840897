import {Component, OnInit} from '@angular/core';
import {SearchService} from '../../services/search/search.service';
import {ActivatedRoute} from '@angular/router';
import {BreadCrumbItem} from '../../shared/models/breadcrumb-item.model';
import {BreadcrumbService} from '../../shared/services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {
  public searchText: string;
  public searchData: any[];
  public itemsPerPage: number;
  public currentPage: number;
  public count: number;
  public isLoading = true;

  constructor(private searchService: SearchService,
              private route: ActivatedRoute,
              private breadCrumbService: BreadcrumbService) {
  }

  ngOnInit() {
    this.doSearch(1);
    this.breadCrumbService.setBreadCrumb([new BreadCrumbItem('Search results')]);
  }

  public doSearch(event: number): number {
    this.isLoading = true;
    this.route.queryParams.subscribe((data) => {
      this.searchData = null;
      let searchString = data['q'];

      this.searchText = searchString;
      this.searchService.getSearchResults(searchString, event).subscribe((res) => {
        this.currentPage = res.pager.current_page + 1;
        this.count = res.pager.count;
        this.itemsPerPage = res.pager.items_per_page;
        this.searchData = res.data;
        this.isLoading = false;
      });
    });

    return event;
  }
}