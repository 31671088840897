import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BackendApiService } from '../../shared/services/backend-api/backend-api.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormService } from '../../services/form/form.service';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { PagetitleService } from '../../services/page-title/pagetitle.service';
import { FormPaymentComponent } from '../form-payment/form-payment.component';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-honour-program-form',
  templateUrl: './honour-program-form.component.html',
  styleUrls: ['./honour-program-form.component.scss']
})
export class HonourProgramFormComponent implements OnInit {
  public honourProgramForm: FormGroup;
  popupState: PopupState;
  public popupStates = PopupState;
  public popupMessage: string;
  popUpOptions: PopupState | null = null;
  public submitAttempt = false;
  public pageTitle = 'The Honours Programme';
  public signingDate: string;
  public sendingCurrently: boolean = false;
  @ViewChild('termsAndConditions') termsAndConditions: TermsAndConditionsComponent;
  @ViewChild('formPayment') formPayment: FormPaymentComponent;

  constructor(private backendApiService: BackendApiService,
              public formBuilder: FormBuilder,
              public ngxSmartModalService: NgxSmartModalService,
              public formService: FormService,
              private pageTitleService: PagetitleService) {
    this.createHonourProgramForm();
    this.pageTitleService.setPageTitle(this.pageTitle).setPrintedTitle(this.pageTitle);
  }

  ngOnInit() {
  }

  protected createHonourProgramForm() {
    this.honourProgramForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      dobDay: ['', [Validators.required]],
      dobMonth: ['', [Validators.required]],
      dobYear: ['', [Validators.required]],
      city: ['', [Validators.required]],
      street: ['', [Validators.required]],
      followingAddress: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      country: ['', [Validators.required]],
      tel: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      registerMeUnder: ['', [Validators.required]],
      acceptInvitation: ['', [Validators.required]],
      howToList: ['', [Validators.required]],
      completedDate: ['', [Validators.required]],
      paymentDate: ['', [Validators.required]],
      paymentAmount: ['', [Validators.required]],
      date: ['', [Validators.required]],
      signature: ['', [Validators.required]],
      termsAndConditions: ['', [Validators.required]],
      captcha: [''],
    });
  }

  private getPostData() {
    return {
      firstName: this.honourProgramForm.get('firstName').value,
      lastName: this.honourProgramForm.get('lastName').value,
      dobDay: this.honourProgramForm.get('dobDay').value,
      dobMonth: this.honourProgramForm.get('dobMonth').value,
      dobYear: this.honourProgramForm.get('dobYear').value,
      followingAddress: this.honourProgramForm.get('followingAddress').value,
      postalCode: this.honourProgramForm.get('postalCode').value,
      country: this.honourProgramForm.get('country').value,
      city: this.honourProgramForm.get('city').value,
      street: this.honourProgramForm.get('street').value,
      tel: this.honourProgramForm.get('tel').value,
      email: this.honourProgramForm.get('email').value,
      registerMeUnder: this.honourProgramForm.get('registerMeUnder').value,
      acceptInvitation: this.honourProgramForm.get('acceptInvitation').value,
      howToList: this.honourProgramForm.get('howToList').value,
      completedDate: this.honourProgramForm.get('completedDate').value,
      payment: this.formPayment.getValues(),
      date: this.honourProgramForm.get('date').value,
      signature: this.honourProgramForm.get('signature').value,
      termsAndConditions: this.honourProgramForm.get('termsAndConditions').value,
      captcha: this.termsAndConditions.captchaValue
    };
  }

  public submit(): void {
    this.honourProgramForm.get('signature').setValidators([Validators.required]);
    this.honourProgramForm.get('signature').updateValueAndValidity({emitEvent: true});

    // Indicate we tried to submit once!
    this.submitAttempt = true;
    // Validate before submit.
    // todo separate later validate and submit.
    if (this.honourProgramForm.valid && this.termsAndConditions.captchaSuccessful && !this.sendingCurrently) {
      this.backendApiService.setRestEndpoint();
      this.sendingCurrently = true;

      this.backendApiService.post('honour_program_form/submit', this.getPostData()).pipe(
        finalize(() => {
          this.sendingCurrently = false;
          this.termsAndConditions.reCaptcha.reset();
        })
      ).subscribe((resp: any) => {
        this.popupState = PopupState.Passed;
        if (resp.success === true) {
          this.popupMessage = resp.message;
          this.ngxSmartModalService.getModal('myHonourProgramModal').open();
          // Reset back after submission.
          this.reset();
        }
      }, (err) => {
        this.popupState = PopupState.Failed;
        this.popupMessage = err.error.message;
        this.ngxSmartModalService.getModal('myHonourProgramModal').open();
      });
    }
  }

  public print(ev): void {
    ev.preventDefault();

    this.submitAttempt = true;

    this.honourProgramForm.get('signature').setValidators(null);

    Object.keys(this.honourProgramForm.controls).forEach(field => {
      const control = this.honourProgramForm.get(field);
      control.markAsTouched();
      control.updateValueAndValidity({emitEvent: true});
    });

    if (this.honourProgramForm.valid) {
      this.formService.print();

      this.reset();
    }
  }

  // Resets the form and submit attempt.
  public reset() {
    this.honourProgramForm.reset();
    this.termsAndConditions.reCaptcha.reset();
    this.submitAttempt = false;
  }

  public closeModal(): void {
    this.ngxSmartModalService.getModal('myHonourProgramModal').close();
  }
}

export enum PopupState {
  Passed,
  Failed
}
