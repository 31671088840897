import { Paragraph } from "./paragraph.model";

export class ContentWithParagraphs {
  private paragraphs: Paragraph[];

  setParagraphs(paragraphs: Paragraph[]) {
    this.paragraphs = paragraphs;
  }

  getParagraphs(): Paragraph[] {
    return this.paragraphs;
  }
}