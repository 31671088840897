import { Component, OnInit } from '@angular/core';
import { PagetitleService } from '../../services/page-title/pagetitle.service';
import { HeaderService } from '../../services/header/header.service';

@Component({
  selector: 'app-searchresultspage',
  templateUrl: './searchresultspage.component.html',
  styleUrls: ['./searchresultspage.component.scss']
})
export class SearchresultspageComponent implements OnInit {

  constructor(private pageTitleService: PagetitleService, private headerService: HeaderService) {
    this.pageTitleService.setPageTitle('Search results');
    this.pageTitleService.setPrintedTitle('Search results');
    this.headerService.setHeaderImage('assets/img/header-img-search-results.jpg');
    this.headerService.shareButtons(false);
  }

  ngOnInit() {
  }

}
