import {Injectable} from '@angular/core';
import {BackendApiService} from '../backend-api/backend-api.service';
import {Observable} from 'rxjs';
import {Paragraph} from '../../models/paragraph.model';

@Injectable()
export class ParagraphService {

  constructor(private backendApiService: BackendApiService) {
  }

  getParagraphs(paragraphs: number[]): Observable<Paragraph[]> {
    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('paragraphs/' + encodeURIComponent(paragraphs.join('+')))
      .map((res: any) => {
        let stories: Paragraph[] = [];
        for (let index in res.data) {
          stories.push(new Paragraph().deserialize(res.data[index]));
        }
        return stories;
      });
  }

  getIssuuOembed(url: string) {
    return this.backendApiService.getThirdParty('https://issuu.com/oembed', { url, iframe: 'true' });
  }
}
