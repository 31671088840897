import { AnnualReport } from './../../shared/models/annual-report.model';
import { Component, OnInit } from '@angular/core';
import { NguCarousel } from '@ngu/carousel';
import { Observable } from 'rxjs';
import { BlockHandlerService } from '../../shared/services/block-handler/block-handler.service';
import { StoriesService } from '../../shared/services/stories/stories.service';
import { Story } from '../../shared/models/story.model';
import { AnnualReportService } from '../../shared/services/annual-report/annual-report.service';

@Component({
  selector: 'app-three-boxes',
  templateUrl: './three-boxes.component.html',
  styleUrls: ['./three-boxes.component.scss']
})
export class ThreeBoxesComponent implements OnInit {
  blocks: {};
  annualReportsObservable: Observable<AnnualReport[]> = new Observable();
  filesInSlide = 3;
  fileSlides: BlockFiles[][];
  carouselOne: NguCarousel;

  constructor(private blockHandlerService: BlockHandlerService, private annualReportService: AnnualReportService) { }

  ngOnInit() {
    this.blocks = {
      'membership-list': new Observable(),
      'become-a-bp-fellow': new Observable(),
      'become-a-member-of-honours-programme': new Observable(),
    };

    for (let key of Object.keys(this.blocks)) {
      this.blocks[key] = this.blockHandlerService.getBlockData(key);
    }

    this.blocks['membership-list'].subscribe((res: any) => {
      let files = res.files,
        counter = 1;

      this.fileSlides = Array(Math.ceil(files.length / this.filesInSlide)).fill([]).map(() => []);

      for (let i = 0; i < files.length; i++) {
        if (i < counter * this.filesInSlide) {
          this.fileSlides[counter - 1].push(files[i]);
        }

        if (i === (counter * this.filesInSlide) - 1) {
          counter++;
        }
      }
    });

    this.annualReportsObservable = this.annualReportService.getAnnualReports();

    this.carouselOne = {
      grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
      slide: 1,
      speed: 400,
      interval: 4000,
      point: {
        visible: false
      },
      load: 1,
      touch: true,
      loop: false
    };
  }

}
