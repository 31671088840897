import {HeaderService} from './../../../services/header/header.service';
import {Component, OnInit} from '@angular/core';
import {PagetitleService} from '../../../services/page-title/pagetitle.service';
import {MenuService} from '../../../services/menu/menu.service';
import {Menu} from '../../../models/menu';
import {NguCarousel} from '@ngu/carousel';
import {BlockHandlerService} from '../../../shared/services/block-handler/block-handler.service';
import {Observable, Subject} from 'rxjs';
import {Block} from '../../../shared/models/block.model';
import {
  NavigationEnd, Router
} from '@angular/router';
import {BackendApiService} from '../../../shared/services/backend-api/backend-api.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import { Paragraph } from '../../../shared/models/paragraph.model';
import { BasicPageService } from '../../../shared/services/basic-page/basic-page.service';
import { BasicPage } from '../../../shared/models/basic-page.model';
import { Image } from '../../../shared/models/image.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // Indicates whether the given page is at home.
  public isMenu = false;
  public isMenuItem = false;
  public isShare = false;
  public mainMenuItems: Menu[] = [];
  public mainMenuMachineName: string;
  public mainMenuAdminPath: string;
  public secondaryMenuMachineName: string;
  public secondaryMenuAdminPath: string;
  public secondaryMenuItems: Menu[] = [];
  public shareClickedOnce: boolean = false;
  public slidesBlock: Observable<Block>;
  private isAdmin: boolean = null;

  public headerAdminLink: string;

  public carouselOne: NguCarousel = {
    grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
    speed: 400,
    interval: 50000000,
    point: {
      visible: true,
      pointStyles: `
                .ngucarouselPoint {
                text-align: center;
                padding: 12px;
                margin: 0;
                white-space: nowrap;
                overflow: auto;
                width: 100%;
                bottom: 20px;
                box-sizing: border-box;
              }
              .ngucarouselPoint li {
                display: inline-block;
                border-radius: 999px;
                background: #cb153c;
                padding: 5px;
                margin: 0 3px;
                transition: .4s ease all;
                cursor: pointer;
              }
              .ngucarouselPoint li.active {
                 padding: 7px;
              }
            `
    },
    load: 2,
    touch: true,
    loop: true,
    custom: 'banner',
  };

  public constructor(private pageTitleService: PagetitleService,
                     private _menuService: MenuService,
                     private headerService: HeaderService,
                     private blockHandlerService: BlockHandlerService,
                     private api: BackendApiService,
                     private basicPageService: BasicPageService,
                     private router: Router,
                     private ngxSmartModalService: NgxSmartModalService) {

    this.mainMenuMachineName = 'main';
    this.mainMenuAdminPath = '/admin/structure/menu/manage/' + this.mainMenuMachineName;
    this.secondaryMenuMachineName = 'contact-menu';
    this.secondaryMenuAdminPath = '/admin/structure/menu/manage/' + this.secondaryMenuMachineName;
    this.isAdmin = api.isAdmin.getValue();
    api.isAdmin.subscribe((res: boolean) => this.isAdmin = res);
    this.processRouteEvents();
  }

  public getPageTitle(): string {
    return this.pageTitleService.getPrintedTitle();
  }

  public getPageInfo(): string {
    return this.pageTitleService.getPageInfo();
  }

  ngOnInit() {
    this._menuService.getMenu('main').subscribe((res) => {
      this.mainMenuItems = res;
    });
    this._menuService.getMenu('contact-menu').subscribe((res) => {
      this.secondaryMenuItems = res;
    });
  }

  public getHeaderService(): HeaderService {
    return this.headerService;
  }

  pageUrl() {
  }

  public openSearchModal(): void {
    this.ngxSmartModalService.getModal('searchModal').open();
  }

  public closeModal(): void {
    this.ngxSmartModalService.getModal('searchModal').close();
  }

  private processRouteEvents(): void {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // In case this is a basic page, we use the header image from the basic page CT
          if (event.url.indexOf('/page') == 0) {
            this.basicPageService.getPageData('page/' + event.url.replace('/page/', ''))
              .subscribe((res: BasicPage) => {
                const block: Block = new Block();
                const paragraph = new Paragraph();
                paragraph.setType('image_slider');
                paragraph.image = new Image(res.image.src, 'aaa');
                block.setParagraphs([paragraph]);
                this.slidesBlock = Observable.of(block);
                this.headerAdminLink = res.editLink;
              })
          }
          // In all other cases there should be a dedicated block that controls the header image
          else {
            this.slidesBlock = this.blockHandlerService.getTopSlider(event);
            this.slidesBlock.subscribe((res: any) => {
              this.headerAdminLink = '/block/' + res.id;
              if (res.slides_paragraphs) {
                this.carouselOne.point.visible = res.slides_paragraphs.length > 1;
              }
            });
          }
        }
      });
  }
}
