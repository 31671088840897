import { BasicPageService } from './../../shared/services/basic-page/basic-page.service';
import { BasicPage } from './../../shared/models/basic-page.model';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { BlockHandlerService } from '../../shared/services/block-handler/block-handler.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-teams-detail',
  templateUrl: './teams-detail.component.html',
  styleUrls: ['./teams-detail.component.scss']
})
export class TeamsDetailComponent implements OnInit {

  pageData: Observable<BasicPage> = new Observable;

  constructor(private route: ActivatedRoute, private basicPageService: BasicPageService) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        this.pageData = this.basicPageService.getPageData('team/' + params['alias']);
        try { window.scrollTo({ left: 0, top: 0, behavior: 'smooth' }); } catch (e) { window.scrollTo(0, 0); }
      });
  }
}
