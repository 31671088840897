import {Injectable, Sanitizer} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';



import {BackendApiService} from '../backend-api/backend-api.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ScoutProject} from '../../models/scout-project';
import {DomSanitizerImpl} from '@angular/platform-browser/src/security/dom_sanitization_service';
import {SocialChannel} from '../../models/social-channel.model';

@Injectable()
export class ScoutDonateService {

  constructor(private backendApi: BackendApiService, private sanitizer: DomSanitizer) {
  }

  public getProjects(): Observable<ScoutProject[]> {
    this.backendApi.setRestEndpoint();
    return this.backendApi.get('projects')
      .map((res: any) => {
        const projects: ScoutProject[] = [];
        for (let i = 0; i < res.data.length; ++i) {
          if (res.data[i]) {
            const url = 'https://donate.scout.org/t/' + res.data[i];
            const safeHtml = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            projects.push(new ScoutProject(res.data[i], safeHtml));
          }
        }
        return projects;
      });
  }
}
