import {Component, OnInit, ViewChild} from '@angular/core';
import {MediaService} from "../../shared/services/media/media.service";
import {ActivatedRoute} from "@angular/router";
import {Media} from "../../shared/models/media.model";
import {Observable} from "rxjs";
import {NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryComponent, NgxGalleryOrder} from 'ngx-gallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  pageData: Observable<Media> = new Observable;
  images: any[] = [];
  galleryTitle: string;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] = [];

  constructor(private mediaService: MediaService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.galleryOptions = [{
      width: "100%",
      height: "100px",
      previewKeyboardNavigation: true,
      image: false,
      thumbnailsColumns: 4,
      thumbnailsRows: 4,
      thumbnailsOrder: NgxGalleryOrder.Row
    }];

    this.route.params
      .subscribe(params => {
        this.pageData = this.mediaService.getGalleryData('gallery/' + params['alias']);
      });
  }
}
