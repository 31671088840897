import { StoriesService } from './../../shared/services/stories/stories.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NguCarousel } from '@ngu/carousel';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Story } from '../../shared/models/story.model';
import { BreadcrumbService } from "../../shared/services/breadcrumb/breadcrumb.service";
import { BreadCrumbItem } from "../../shared/models/breadcrumb-item.model";
import { switchMap } from 'rxjs/operators';
import { filter } from 'rxjs-compat/operators/filter';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit, OnDestroy {
  pageData: Observable<Story> = new Observable;
  public carouselOne: NguCarousel;
  fieldFiles: BlockFiles[] = [];
  private subscription: Subscription;
  private mainPath: string;
  private crumbTitle: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private storiesService: StoriesService,
    private breadCrumbService: BreadcrumbService
  ) {
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap((event: NavigationEnd) => {
        // Special case for home.
        const route = event.url === '/' ? '/home' : event.url;
        // Get rid of /
        const path = route.slice(1);
        const pathChunks = path.split('/');
        this.mainPath = pathChunks[0];
        this.crumbTitle = this.mainPath.charAt(0).toUpperCase() + this.mainPath.slice(1);

        return this.activatedRoute.params;
      }),
      switchMap(params => {
        this.pageData = this.storiesService.getStoryData(this.mainPath + '/' + params['alias']);
        return this.pageData;
      })
    ).subscribe(res => {
      this.fieldFiles = res.fieldFiles;
      this.breadCrumbService.setBreadCrumb([new BreadCrumbItem(this.crumbTitle, '/' + this.mainPath), new BreadCrumbItem(res.getTitle())]);
    });
  }

  ngOnInit() {
    this.carouselOne = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 400,
      interval: 4000,
      point: {
        visible: false
      },
      load: 1,
      touch: true,
      loop: true
    };
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

