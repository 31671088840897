import { Image } from './image.model';
import * as moment from 'moment';
import { Url } from "url";
import { Deserializable } from './deserializable.model';

export class AnnualReport implements Deserializable<AnnualReport> {
  private _path: string;
  private _year: moment.Moment;
  private _image: Image;

  /** Deserializes data coming from our own API with only needed data */
  deserialize(input: any): AnnualReport {
    this.year = moment(input.year);
    this.path = input.path;

    if (input.image != null) {
      this.image = new Image(input.image.url, input.image.alt);
    }
    
    return this;
  }

  /** Returns the year for this object */
  public getYear(): string {
    return this.year.format('Y');
  }

  /**
   * Getter path
   * @return {string}
   */
	public get path(): string {
		return this._path;
	}

  /**
   * Getter year
   * @return {moment.Moment}
   */
	public get year(): moment.Moment {
		return this._year;
	}

  /**
   * Setter path
   * @param {string} value
   */
	public set path(value: string) {
		this._path = value;
	}

  /**
   * Setter year
   * @param {moment.Moment} value
   */
	public set year(value: moment.Moment) {
		this._year = value;
	}
  
  /**
   * Getter image
   * @return {Image}
   */
	public get image(): Image {
		return this._image;
	}

  /**
   * Setter image
   * @param {Image} value
   */
	public set image(value: Image) {
		this._image = value;
	}
  
}