import {Component, OnInit} from '@angular/core';
import {PagetitleService} from '../../services/page-title/pagetitle.service';
import {HeaderService} from '../../services/header/header.service';
import {BreadcrumbService} from '../../shared/services/breadcrumb/breadcrumb.service';
import {BreadCrumbItem} from '../../shared/models/breadcrumb-item.model';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {BasicPageService} from '../../shared/services/basic-page/basic-page.service';
import {BasicPage} from '../../shared/models/basic-page.model';
import {RelatedArticles} from '../../shared/models/related-articles';
import { SidebarTemplate } from '../../shared/models/sidebar-template.model';

@Component({
  selector: 'app-about-wsf-page',
  templateUrl: './about-wsf-page.component.html',
  styleUrls: ['./about-wsf-page.component.scss']
})
export class AboutWsfPageComponent implements OnInit {
  public pageData: Observable<BasicPage> = new Observable;
  public relatedArticles: RelatedArticles[];
  public fieldFiles: BlockFiles[] = [];
  public urlParam: string;
  public sidebar: SidebarTemplate;
  public page: BasicPage;

  constructor(private pageTitleService: PagetitleService,
              private headerService: HeaderService,
              private breadCrumbService: BreadcrumbService,
              private route: ActivatedRoute,
              private basicPageService: BasicPageService) { }

  ngOnInit() {
    this.headerService.setHeaderImage('assets/img/header-img-about-wsf.jpg');
    this.headerService.shareButtons(true);

    this.route.params
      .subscribe(params => {
        this.urlParam = params.alias;
        this.basicPageService.getPageData('page/' + params['alias'])
          .subscribe((res) => {
            this.page = res;
            this.relatedArticles = res.relatedArticles;
            this.fieldFiles = res.fieldFiles;
            this.sidebar = res.sidebarTemplate;
            this.breadCrumbService.setBreadCrumb([new BreadCrumbItem(res.getTitle())]);
            this.pageTitleService.setPageTitle('About WSF');
            this.pageTitleService.setPrintedTitle(res.getTitle());
          });
      });
  }
}
