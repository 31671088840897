import {Component, OnInit} from '@angular/core';
import {TeamsService} from "../../shared/services/teams/teams.service";
import {Teams} from "../../shared/models/teams.model";

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss']
})
export class TeamListComponent implements OnInit {
  public team: Teams[] = [];
  public loadMoreEnabled: boolean = true;
  public teamListPager: number[];
  public members: Teams[] = [];
  public memberType: string = 'board';
  public filters = {
    pageNumber: 0,
    wsf_team_type: 'board'
  };
  adminButtonsConfig: any;

  constructor(private teamService: TeamsService) {
    this.adminButtonsConfig = {
      links: {
        'Add new member': '/node/add/wsf_team',
        'Reorder team': '/admin/structure/taxonomy/manage/job_title/overview'
      }
    }
  }

  ngOnInit() {
    this.loadNames(this.filters);
    this.switchMemberType('board');
  }

  // Load member list
  public loadTeamList(type: string): void {
    this.teamService.getTeamList(this.filters)
      .subscribe(res => {
        if (type === 'more') {
          // Add array of next team members to the current array of team members
          Array.prototype.push.apply(this.team, res);
        } else if (type === 'switch') {
          // Load other member types into the array
          this.team = res;
        }

        // Get pager data from service
        this.teamListPager = this.teamService.getTeamListPager();

        // Disabling load more button if there are no more pages to load
        if ((this.teamListPager[1] - 1) === this.teamListPager[0]) {
           this.loadMoreEnabled = false;
        }
      });
  }

  // Load more members
  public loadMore(): void {
    this.filters.pageNumber++;
    this.loadTeamList('more');
  }

  // Load member names in the grid
  public loadNames(filter): void {
    this.teamService.getNames(filter)
      .subscribe((res) => {
        this.members = res;
      });
  }

  // Switch between board and staff member type
  public switchMemberType(type: string): void {
    if (type === 'board') {
      this.filters.wsf_team_type = 'board';
    } else {
      this.filters.wsf_team_type = 'staff';
    }

    // Reset filter and button variables
    this.filters.pageNumber = 0;
    this.loadMoreEnabled = true;

    // Variable for switching 'active' class in view
    this.memberType = type;
    this.loadNames(this.filters);
    this.loadTeamList('switch');
  }
}