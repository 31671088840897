// Import some stuff from the core library
import { Directive, ElementRef, Input, Renderer } from '@angular/core';

// create a Directive to decorate. To prevent confusion let's call this myHighlight to
// illustrate the fact this this is a custom appearance
@Directive({ selector: 'a' })

export class LinkNewWindowDirective {
    constructor(el: ElementRef, renderer: Renderer) {

      const hasClass = (element, className) => {
        return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
      };
      if (!hasClass(el.nativeElement, 'ignore-directive') && (el.nativeElement.href.indexOf('http') === 0 || el.nativeElement.href.indexOf('www') === 0)) {
        renderer.setElementProperty(el.nativeElement, 'target', '_blank');
      }
    }
}
