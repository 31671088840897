import { Component, OnInit } from '@angular/core';
import { PagetitleService } from '../../services/page-title/pagetitle.service';
import { HeaderService } from '../../services/header/header.service';
import {BreadcrumbService} from "../../shared/services/breadcrumb/breadcrumb.service";
import {BreadCrumbItem} from "../../shared/models/breadcrumb-item.model";

@Component({
  selector: 'app-medialistpage',
  templateUrl: './medialistpage.component.html',
  styleUrls: ['./medialistpage.component.scss']
})
export class MedialistpageComponent implements OnInit {

  constructor(private pageTitleService: PagetitleService,
              private headerService: HeaderService,
              private breadCrumbService: BreadcrumbService) {
    this.pageTitleService.setPageTitle('Media');
    this.pageTitleService.setPrintedTitle('Media');
    this.headerService.setHeaderImage('assets/img/header-img-media-list.jpg');
    this.headerService.shareButtons(false);
  }

  ngOnInit() {
    this.breadCrumbService.setBreadCrumb([new BreadCrumbItem('Media')]);
  }
}
