import { ParagraphService } from './../paragraph/paragraph.service';
import { BasicPage } from './../../models/basic-page.model';
import { Injectable } from '@angular/core';
import { BackendApiService } from '../backend-api/backend-api.service';
import { Observable } from 'rxjs';

@Injectable()
export class BasicPageService {

  constructor(private backendApiService: BackendApiService, private paragraphService: ParagraphService) { }

  getPageData(machineName: string): Observable<BasicPage> {
    this.backendApiService.setNodeEndpoint();
    return this.backendApiService.get(machineName)
      .map((res: any) => {
        let page = new BasicPage().deserialize(res);

        let paragraphIds:number[] = [];
        for (let paragraph of res.field_paragraphs) {
          paragraphIds.push(paragraph.target_id);
        }

        this.paragraphService.getParagraphs(paragraphIds)
        .subscribe((res: any) => {
          page.setParagraphs(res);
        });

        return page;
      });
  }
}
