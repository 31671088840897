import { StoriesService } from './../../shared/services/stories/stories.service';
import { Component, OnInit } from '@angular/core';
import { Story } from '../../shared/models/story.model';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit {
  stories: Story[];

  constructor(private storiesService: StoriesService) { }

  ngOnInit() {
    this.storiesService.getMainPageStories()
    .subscribe((res: any) => {
      this.stories = res;
    });
  }
}
