import { Injectable } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Injectable()
export class PagetitleService {

  static readonly siteName = 'World Scout Foundation';

  private coreTitleService: Title;

  private rawPageTitle = '';

  private printedTitle = '';

  private pageInfo = '';

  constructor(coreTitleService: Title) {
    this.coreTitleService = coreTitleService;
  }

  public getRawPageTitle(): string {
    return this.rawPageTitle;
  }

  public setPageTitle(title: string) {
    this.rawPageTitle = title;
    title += ' | ' + PagetitleService.siteName;
    this.coreTitleService.setTitle(title);
    return this;
  }

  public getPrintedTitle(): string {
    return this.printedTitle;
  }

  public getPageInfo(): string {
    return this.pageInfo;
  }

  public setPrintedTitle(title: string) {
    this.printedTitle = title;
    return this;
  }

  public setPageInfo(info: string) {
    this.pageInfo = info;
  }

}
