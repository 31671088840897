import {Component, OnInit, Input} from '@angular/core';
import {Block} from "../../shared/models/block.model";
import {Observable} from "rxjs";
import {BlockHandlerService} from "../../shared/services/block-handler/block-handler.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-plain-text',
  templateUrl: './plain-text.component.html',
  styleUrls: ['./plain-text.component.scss']
})
export class PlainTextComponent implements OnInit {
  @Input() blockMachineName: string;
  blockObservable: Observable<Block> = new Observable();

  constructor(private blockHandlerService: BlockHandlerService) {
  }

  ngOnInit() {
    this.blockObservable = this.blockHandlerService.getBlockData(this.blockMachineName);
  }
}