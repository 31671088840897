import {NgxSmartModalModule} from 'ngx-smart-modal';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {RouterModule, Routes, NavigationEnd, UrlSegment} from '@angular/router';
import {BrowserModule, Title} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';

import {AgmCoreModule} from '@agm/core';
import {NguCarouselModule} from '@ngu/carousel';
import 'hammerjs';
import {NgxGalleryModule} from 'ngx-gallery';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';

import {BackendApiService} from './shared/services/backend-api/backend-api.service';
import {FakeBackendProvider} from './shared/mocks/fake-backend';

// Services
import { BlockAdminService } from './shared/services/block-admin/block-admin.service';
import { BodyClassService } from './services/body-class/body-class.service';
import { HeaderService } from './services/header/header.service';
import { PagetitleService } from './services/page-title/pagetitle.service';
import { BlockHandlerService } from './shared/services/block-handler/block-handler.service';
import { BodyClassListenerService } from './services/body-class-listener/body-class-listener.service';
import { EventsService } from './shared/services/events/events.service';
import { MediaService } from './shared/services/media/media.service';
import { StoriesService } from './shared/services/stories/stories.service';
import { MenuService } from './services/menu/menu.service';
import { SocialCounterService } from './shared/services/social/social-counter.service';
import { BasicPageService } from './shared/services/basic-page/basic-page.service';
import { ParagraphService } from './shared/services/paragraph/paragraph.service';
import { ScoutDonateService } from './shared/services/scout-donate/scout-donate.service';
import { TeamsService } from './shared/services/teams/teams.service';
import { BreadcrumbService } from "./shared/services/breadcrumb/breadcrumb.service";
import { AnnualReportService } from './shared/services/annual-report/annual-report.service';
import { SearchService } from './services/search/search.service';
import { ProjectService } from './shared/services/project/project.service';

// Components
import { HeaderComponent } from './components/site-layout/header/header.component';
import { FooterComponent } from './components/site-layout/footer/footer.component';
import { BreadcrumbComponent } from './components/site-layout/breadcrumb/breadcrumb.component';
import { LocationComponent } from './components/location/location.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { TeamListComponent } from './components/team-list/team-list.component';
import { PlainTextComponent } from './components/plain-text/plain-text.component';
import { AboutWsfComponent } from './components/about-wsf/about-wsf.component';
import { ScoutsComponent } from './components/scouts/scouts.component';
import { SocialCounterComponent } from './components/social-counter/social-counter.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { NewsHighlightedComponent } from './components/news-highlighted/news-highlighted.component';
import { EventsLatestMediaComponent } from './components/events-latest-media/events-latest-media.component';
import { StoriesComponent } from './components/stories/stories.component';
import { ScoutStoriesComponent } from './components/scout-stories/scout-stories.component';
import { EventsListComponent } from './components/events-list/events-list.component';
import { ParagraphComponent } from './components/paragraph/paragraph.component';
import { MainpageComponent } from './pages/mainpage/mainpage.component';
import { TeamspageComponent } from './pages/teamspage/teamspage.component';
import { ContactpageComponent } from './pages/contactpage/contactpage.component';
import { NewspageComponent } from './pages/newspage/newspage.component';
import { OuractionspageComponent } from './pages/ouractionspage/ouractionspage.component';
import { AboutWsfPageComponent } from './pages/about-wsf-page/about-wsf-page.component';
import { DonatepageComponent } from './pages/donatepage/donatepage.component';
import { DonateFormComponent } from './components/donate-form/donate-form.component';
import { AboutWsfDetailedComponent } from './components/about-wsf-detailed/about-wsf-detailed.component';
import { DonationPlatformComponent } from './components/donation-platform/donation-platform.component';
import { JoinUsComponent } from './components/join-us/join-us.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { SearchresultspageComponent } from './pages/searchresultspage/searchresultspage.component';
import { ThreeBoxesComponent } from './components/three-boxes/three-boxes.component';
import { NewsDetailComponent } from './components/news-detail/news-detail.component';
import { NewsDetailPageComponent } from './pages/news-detail-page/news-detail-page.component';
import { EventdetailpageComponent } from './pages/eventdetailpage/eventdetailpage.component';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import { AsideEventGalleryComponent } from './components/aside-event-gallery/aside-event-gallery.component';
import { AsideFilesComponent } from './components/aside-files/aside-files.component';
import { MedialistpageComponent } from './pages/medialistpage/medialistpage.component';
import { GalleriesListComponent } from './components/galleries-list/galleries-list.component';
import { MediagallerypageComponent } from './pages/mediagallerypage/mediagallerypage.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { FeaturedProjectsComponent } from './components/featured-projects/featured-projects.component';
import { DrupalImageComponent } from './components/drupal-image/drupal-image.component';
import { AdminButtonsComponent } from './components/shared/admin-buttons/admin-buttons.component';
import { TeamsDetailPageComponent } from './pages/teams-detail-page/teams-detail-page.component';
import { TeamsDetailComponent } from './components/teams-detail/teams-detail.component';
import { VideoModalComponent } from './components/video-modal/video-modal.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RelatedArticlesComponent } from './components/related-articles/related-articles.component';
import { HonourProgramPageComponent } from './pages/honour-program-page/honour-program-page.component';
import { HonourProgramFormComponent } from './components/honour-program-form/honour-program-form.component';
import { ImageBlockComponent } from './components/image-block/image-block.component';

// Pipes
import { StripHtmlPipe } from './shared/pipes/strip-html.pipe';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { NumbersPipe } from './shared/pipes/numbers.pipe';

// Directives
import { BlockAdminDirective } from './shared/directives/block-admin.directive';
import { LinkNewWindowDirective } from './shared/directives/link-new-window.directive';
import { RecaptchaModule } from 'ng-recaptcha';
import { FormPaymentComponent } from './components/form-payment/form-payment.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { FormService } from './services/form/form.service';
import { WindowService } from './services/window/window.service';
import { BadenPowellFellowshipPageComponent } from './pages/baden-powell-fellowship-page/baden-powell-fellowship-page.component';
import { BadenPowellFellowshipFormComponent } from './components/baden-powell-fellowship-form/baden-powell-fellowship-form.component';
import { BpfPledgeComponent } from './components/bpf-pledge/bpf-pledge.component';
import { BpfYoungComponent } from './components/bpf-young/bpf-young.component';
import { BpfGiftComponent } from './components/bpf-gift/bpf-gift.component';
import { NgbCustomDateParserFormatter } from './shared/formatters/ngb-custom-date-parser-formatter';
import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { ProjectDetailPageComponent } from './pages/project-detail-page/project-detail-page.component';
import { SidebarTemplateComponent } from './components/sidebar-template/sidebar-template.component';
import { FilesBlockComponent } from './components/sidebar-template/files-block/files-block.component';
import { InfoBlockComponent } from './components/sidebar-template/info-block/info-block.component';
import { MainComponent } from './components/main/main.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const appRoutes: Routes = [
  {path: '', component: MainComponent, children: [
    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {path: 'home', component: MainpageComponent},
    {path: 'our-actions', component: OuractionspageComponent},
    {path: 'page/:alias', component: AboutWsfPageComponent},
    {path: 'news', component: NewspageComponent},
    {path: 'teams', component: TeamspageComponent},
    {path: 'donate', component: DonatepageComponent},
    {path: 'contact', component: ContactpageComponent},
    {path: 'search-results', component: SearchresultspageComponent},
    {path: 'news/:alias', component: NewsDetailPageComponent},
    {path: 'story/:alias', component: NewsDetailPageComponent},
    {path: 'events', component: OuractionspageComponent},
    {path: 'event/:alias', component: EventdetailpageComponent},
    {path: 'projects/:alias', component: ProjectDetailPageComponent},
    {path: 'media-list', component: MedialistpageComponent},
    {path: 'gallery/:alias', component: MediagallerypageComponent},
    {path: 'team/:alias', component: TeamsDetailPageComponent},
    {path: 'honours-programme', component: HonourProgramPageComponent},
    {path: 'bp-fellowship', component: BadenPowellFellowshipPageComponent},
    {path: 'bp-fellowship/:alias', component: BadenPowellFellowshipPageComponent},
    {path: 'scholarships', redirectTo: 'page/scholarships', pathMatch: 'full'},
    {path: '404', component: PageNotFoundComponent},
  ]},
  {path: 'appeal', redirectTo: 'donate', pathMatch: 'full'},
  // On client request we are redirecting to /donate
  {path: 'annualappeal', redirectTo: 'donate', pathMatch: 'full'},
  {path: ':alias', loadChildren: './donation-landing/donation-landing.module#DonationLandingModule'},
  {path: '', component: MainComponent, children: [
    {path: '**', component: PageNotFoundComponent},
  ]}
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LocationComponent,
    ContactFormComponent,
    TeamListComponent,
    PlainTextComponent,
    AboutWsfComponent,
    ScoutsComponent,
    SocialCounterComponent,
    TeamspageComponent,
    MainpageComponent,
    ContactpageComponent,
    BreadcrumbComponent,
    NewsListComponent,
    NewsHighlightedComponent,
    NewspageComponent,
    EventsLatestMediaComponent,
    StoriesComponent,
    ScoutStoriesComponent,
    EventsListComponent,
    OuractionspageComponent,
    AboutWsfPageComponent,
    DonatepageComponent,
    DonateFormComponent,
    AboutWsfDetailedComponent,
    DonationPlatformComponent,
    JoinUsComponent,
    SearchResultsComponent,
    SearchresultspageComponent,
    ThreeBoxesComponent,
    NewsDetailComponent,
    NewsDetailPageComponent,
    EventdetailpageComponent,
    EventDetailComponent,
    AsideEventGalleryComponent,
    AsideFilesComponent,
    ParagraphComponent,
    MedialistpageComponent,
    GalleriesListComponent,
    MediagallerypageComponent,
    DrupalImageComponent,
    GalleryComponent,
    FeaturedProjectsComponent,
    AdminButtonsComponent,
    TeamsDetailPageComponent,
    TeamsDetailComponent,
    StripHtmlPipe,
    SafeHtmlPipe,
    BlockAdminDirective,
    LinkNewWindowDirective,
    TeamsDetailComponent,
    VideoModalComponent,
    RelatedArticlesComponent,
    HonourProgramPageComponent,
    HonourProgramFormComponent,
    FormPaymentComponent,
    TermsAndConditionsComponent,
    NumbersPipe,
    BadenPowellFellowshipPageComponent,
    BadenPowellFellowshipFormComponent,
    BpfPledgeComponent,
    BpfYoungComponent,
    BpfGiftComponent,
    PageNotFoundComponent,
    ImageBlockComponent,
    ProjectDetailComponent,
    ProjectDetailPageComponent,
    SidebarTemplateComponent,
    FilesBlockComponent,
    InfoBlockComponent,
    MainComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(
      appRoutes,
      {enableTracing: false}
    ),
    NguCarouselModule,
    NgxSmartModalModule.forRoot(),
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCkCN8wxLJpMypuZ-R7ghFqGZyBwiZHizs'
    }),
    ReactiveFormsModule,
    NgxGalleryModule,
    NgxPaginationModule,
    RecaptchaModule.forRoot(),
    PerfectScrollbarModule,
    NgbModule.forRoot(),
  ],
  providers: [
    Title,
    PagetitleService,
    HeaderService,
    BodyClassService,
    BodyClassListenerService,
    FakeBackendProvider,
    BackendApiService,
    BlockHandlerService,
    EventsService,
    MediaService,
    StoriesService,
    MenuService,
    StoriesService,
    SocialCounterService,
    BasicPageService,
    ParagraphService,
    AnnualReportService,
    ScoutDonateService,
    ProjectService,
    TeamsService,
    BreadcrumbService,
    BlockAdminService,
    SearchService,
    FormService,
    WindowService,
    {
      provide: NgbDateParserFormatter, useClass: NgbCustomDateParserFormatter
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
