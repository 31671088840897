import {Component, OnInit, Input} from '@angular/core';
import {NguCarousel} from '@ngu/carousel';
import {NgxSmartModalService} from "ngx-smart-modal";
import {SafeResourceUrl, DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-aside-event-gallery',
  templateUrl: './aside-event-gallery.component.html',
  styleUrls: [
    '../sidebar-template/sidebar-template.component.scss',
    './aside-event-gallery.component.scss'
  ]
})
export class AsideEventGalleryComponent implements OnInit {
  /** Gallery data for event detail page */
  @Input('eventGalleryBlock') eventGalleryBlocks: EventDetailSideGallery[];
  carouselOne: NguCarousel;
  private regExp: RegExp = /watch\?v=/i;
  public videoUrl: SafeResourceUrl;

  constructor(private ngxSmartModalService: NgxSmartModalService,
              private sanitizer: DomSanitizer) {
    this.carouselOne = {
      grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
      slide: 1,
      speed: 400,
      interval: 4000,
      point: {
        visible: false
      },
      load: 3,
      touch: true,
      loop: false
    };
  }

  ngOnInit() {
  }

  public openVideoModal(item): void {
    if (item.video) {
      this.videoUrl = this.getIframeSrc(item);
    }
    this.ngxSmartModalService.setModalData(item, 'eventVideoModal');
    this.ngxSmartModalService.open('eventVideoModal');
  }

  public getIframeSrc(item): SafeResourceUrl {
    if (item.video !== null) {
      let sanitizedUrl;
      sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(item.video.replace(this.regExp, 'embed/'));
      return sanitizedUrl;
    }
  }

  public imgSrc(item) {
    if (item.video) {
      return 'https://img.youtube.com/vi/' + item.video.split("=").pop() + '/hqdefault.jpg';
    }
    else if (item.images.length !== 0) {
      return item.images[0].url;
    }
    else {
      return item.image.url;
    }
  }
}
