import { Component, OnInit, ViewChild } from '@angular/core';
import {MediaService} from "../../shared/services/media/media.service";
import {Media} from "../../shared/models/media.model";
import { VideoModalComponent } from '../video-modal/video-modal.component';

@Component({
  selector: 'app-galleries-list',
  templateUrl: './galleries-list.component.html',
  styleUrls: ['./galleries-list.component.scss']
})
export class GalleriesListComponent implements OnInit {
  public total: number;
  public currentType: string;
  public currentPage: number;
  public mediaList: Media[] = [];
  public mediaListFilter = {
    type: 'All'
  };
  @ViewChild(VideoModalComponent) videoModalComponent;

  constructor(private mediaService: MediaService) {
  }

  ngOnInit() {
    this.mediaListFiltered('All', 1);
  }

  public mediaListFiltered(filter: string, event?) {
    if (filter !== '') {
      this.mediaListFilter.type = filter;
    }

    this.mediaService.getMediaList(this.mediaListFilter, event).subscribe((res) => {
      this.currentPage = this.mediaService.currentPage;
      this.total = this.mediaService.total;
      this.mediaList = res;
      return event;
    });
  }

  public getMediaUrl(item): string {
    if (!item.video) {
      return item.path;
    }
  }

  public openVideoModal(item): void {
    this.videoModalComponent.open(item);
  }

  public imgSrc(item) {
    if (item.image) {
      return item.image.src;
    }
    else if (item.images.length !== 0) {
      return item.images[0].url;
    }
    else if (item.video) {
      return 'https://img.youtube.com/vi/' + item.video.split("=").pop() + '/hqdefault.jpg';
    }
  }
}
