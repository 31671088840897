import { BasicPage } from './../../shared/models/basic-page.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-about-wsf-detailed',
  templateUrl: './about-wsf-detailed.component.html',
  styleUrls: ['./about-wsf-detailed.component.scss']
})
export class AboutWsfDetailedComponent implements OnInit {
  @Input() pageData: BasicPage;

  constructor() {
  }

  ngOnInit() {
  }
}
