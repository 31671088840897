import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-aside-files',
  templateUrl: './aside-files.component.html',
  styleUrls: ['./aside-files.component.scss']
})
export class AsideFilesComponent implements OnInit {
  /** Files for current event page */
  @Input('fieldFiles') fieldFiles: BlockFiles[] = [];

  constructor() {
  }

  ngOnInit() {

  }
}