import {Component} from '@angular/core';
import {BodyClassListenerService} from './services/body-class-listener/body-class-listener.service';
import { Router, NavigationEnd } from '@angular/router';

declare let ga: Function;
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  constructor(
    protected bodyClassListener: BodyClassListenerService,
    private router: Router) {
      this.bodyClassListener.attach();
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
          gtag('config', 'G-6WYYM4TW76', { page_path: event.urlAfterRedirects });
        }
      });
  }
}
