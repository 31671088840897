import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'admin-buttons',
  templateUrl: './admin-buttons.component.html',
  styleUrls: ['./admin-buttons.component.scss']
})
export class AdminButtonsComponent implements OnInit {
  @Input() config: any;
  public links: any;

  constructor() { }

  ngOnInit() {
    this.links = this.config.links;
  }

}
