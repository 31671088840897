import { Url } from "url";
import { Deserializable } from "./deserializable.model";
import { Paragraph } from "./paragraph.model";
import { Image } from "./image.model";

export class Block implements Deserializable<Block> {
    id: number;
    type: string;
    title: string;
    edit_link: string;
    description: string;
    private _image: Image;
    link: {
        url: string;
        text: string;
        attributes: Attributes;
    };
    private _files: BlockFile[] = [];
    sliderSlides: Paragraph[];

    deserialize(input: any): any {
        Object.assign(this, input);

        if (input) {
          if (input.hasOwnProperty('files') && input.files.length > 0) {
            let finalFiles: BlockFile[] = [];
            let incomingFiles: {url: Url, description: string}[] = input.files;
            for (let file of incomingFiles) {
              let fileObj = new BlockFile();
              fileObj.description = file.description;
              fileObj.url = file.url;
              finalFiles.push(fileObj);
            }
            this._files = finalFiles;
          }

          if (input.hasOwnProperty('image') && input.image != null) {
            this.image = new Image(input.image.url, input.image.alt);
          }
        }

        return this;
    }

    setParagraphs(paragraphs: Paragraph[]) {
        this.sliderSlides = paragraphs;
    }

    getParagraphs(): Paragraph[] {
        return this.sliderSlides;
    }

    getTitle(): string {
        return this.title;
    }

    getDescription(): string {
        return this.description;
    }

    hasLink(): boolean {
        return this.link ? true : false;
    }

    getLinkName(): string {
        return this.link.text;
    }

    getLinkUrl(): string {
        return this.link.url;
    }

    getTarget(): string {
        return this.link.attributes.target;
    }

    getType(): string {
        return this.type;
    }

    /**
     * Getter files
     * @return {BlockFile[] }
     */
	public get files(): BlockFile[]  {
		return this._files;
	}

    /**
     * Setter files
     * @param {BlockFile[] } value
     */
	public set files(value: BlockFile[] ) {
		this._files = value;
    }

    /**
     * Getter image
     * @return {Image}
     */
	public get image(): Image {
		return this._image;
	}

    /**
     * Setter image
     * @param {Image} value
     */
	public set image(value: Image) {
		this._image = value;
	}


}

class BlockFile {
    private _url: Url;
    private _description: string;

    constructor() {
    }

    /**
     * Getter url
     * @return {Url}
     */
	public get url(): Url {
		return this._url;
	}

    /**
     * Setter url
     * @param {Url} value
     */
	public set url(value: Url) {
		this._url = value;
	}

    /**
     * Getter description
     * @return {string}
     */
	public get description(): string {
		return this._description;
	}

    /**
     * Setter description
     * @param {string} value
     */
	public set description(value: string) {
		this._description = value;
	}
}

interface Attributes {
  target: string;
}
