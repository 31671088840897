import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Paragraph } from '../../../shared/models/paragraph.model';

@Component({
  selector: 'app-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss']
})
export class InfoBlockComponent implements OnInit {
  @Input() paragraph: Paragraph;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    if (this.paragraph.layout === 'auto') {
      if (this.paragraph.link && this.paragraph.link.text === this.paragraph.link.url) {
        this.paragraph.link.text = 'More';
      }

      if (!this.paragraph.text) {
        this.paragraph.layout = 'banner';
      } else {
        this.paragraph.layout = 'info_text';
      }
    }
  }

  public goToLink(url: string) {
    if (!url) { return; }

    if (url.indexOf('http') === -1 && url.indexOf('https') === -1) {
      this.router.navigate([url]);
    } else {
      window.open(url, '_blank');
    }
  }

}
