import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {ContactData} from '../../models/contact';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {BackendApiService} from '../../shared/services/backend-api/backend-api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import {Subscription} from 'rxjs';
import { environment } from '../../../environments/environment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit, OnDestroy {
  public contactForm: FormGroup;
  popupState: PopupState;
  popUpOptions: PopupState | null = null;
  public submitAttempt = false;
  public topics: object[];
  public sendingCurrently = false;
  // todo disabled temporarily
  //public topicSubscription: Subscription;
  //public postSubscription: Subscription;
  captchaSuccessful = false;
  captchaValue: string;
  captchaSiteKey = environment.captchaSiteKey;
  @ViewChild('termsAndConditions') termsAndConditions: TermsAndConditionsComponent;

  constructor(private backendApiService: BackendApiService,
              private formBuilder: FormBuilder,
              public ngxSmartModalService: NgxSmartModalService) {
    this.createContactForm();
    this.getTopics();
  }

  ngOnInit() {
  }

  protected createContactForm() {
    this.contactForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      topic: ['', [Validators.required]],
      message: ['', [Validators.required]],
      termsAndConditions: ['', [Validators.required]],
      captcha: [''],
    });
  }

  private getPostData(): ContactData {
    // todo this class is not really needed anymore but w/e
    return new ContactData(
      this.contactForm.get('firstName').value,
      this.contactForm.get('lastName').value,
      this.contactForm.get('email').value,
      this.contactForm.get('topic').value,
      this.contactForm.get('message').value,
      this.contactForm.get('termsAndConditions').value,
      this.termsAndConditions.captchaValue
    );
  }

  public submit(): void {
    // Indicate we tried to submit once!
    this.submitAttempt = true;
    // Validate before submit.
    // todo separate later validate and submit.
    if (this.contactForm.valid && this.termsAndConditions.captchaSuccessful && !this.sendingCurrently) {
      this.sendingCurrently = true;
      this.backendApiService.setRestEndpoint();

      this.backendApiService.post('contact/submit', this.getPostData()).pipe(
        finalize(() => {
          this.sendingCurrently = false;
          this.termsAndConditions.reCaptcha.reset();
        })
      ).subscribe((resp: any) => {
        this.popupState = PopupState.Passed;
        if (resp.data === true) {
          this.ngxSmartModalService.getModal('myContactModal').open();
          // Reset back after submission.
          this.reset();
        }
      }, (err) => {
        this.popupState = PopupState.Failed;
      });
    }
  }

  // Resets the form and submit attempt.
  public reset() {
    this.contactForm.reset();
    this.termsAndConditions.reCaptcha.reset();
    this.submitAttempt = false;
  }

  public closeModal(): void {
    this.ngxSmartModalService.getModal('myContactModal').close();
  }

  public getTopics(): void {
    this.backendApiService.setRestEndpoint();
    this.backendApiService.get('contact/topics').subscribe((res: any) => {
      this.topics = Object.keys(res.data[0]).map(key => ({type: key, value: res.data[0][key]}));
    });
  }

  ngOnDestroy() {
    //this.topicSubscription.unsubscribe();
    //this.postSubscription.unsubscribe();
  }
}

export enum PopupState {
  Passed,
  Failed
}
