import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Block } from '../../shared/models/block.model';
import { BlockHandlerService } from '../../shared/services/block-handler/block-handler.service';

@Component({
  selector: 'app-donation-platform',
  templateUrl: './donation-platform.component.html',
  styleUrls: ['./donation-platform.component.scss']
})
export class DonationPlatformComponent implements OnInit {

  blockObservable: Observable<Block> = new Observable();

  constructor(private blockHandlerService: BlockHandlerService) { }

  ngOnInit() {
    this.blockObservable = this.blockHandlerService.getBlockData('scout-donation-platform');
  }
  
}
