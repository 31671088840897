import {Injectable} from '@angular/core';
import {BackendApiService} from "../backend-api/backend-api.service";
import {Teams} from "../../models/teams.model";

@Injectable()
export class TeamsService {
  teamtListCurrentPage: number;
  teamListTotalPages: number;

  constructor(private backendApiService: BackendApiService) {
  }

  public getTeamList(filters) {
    let params: {page?: number, wsf_team_type?: string} = {};
    if (filters.pageNumber > 0) {
      params.page = filters.pageNumber;
    }
    params.wsf_team_type = filters.wsf_team_type;

    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('wsf-team/list', params)
      .map((res: any) => {
        let teams: Teams[] = [];
        for (let member of res.data) {
          teams.push(new Teams().deserialize(member));
        }

        this.teamListTotalPages = res.pager.pages;
        this.teamtListCurrentPage = res.pager.current_page;
        return teams;
      });
  }

  getNames(filters): any {
    let params: {wsf_team_type?: string} = {};
    params.wsf_team_type = filters.wsf_team_type;

    this.backendApiService.setRestEndpoint();
    return this.backendApiService.get('wsf-team/names', params)
      .map((res: any) => {
        let names: Teams[] = [];
        for (let name of res.data) {
          names.push(new Teams().deserialize(name));
        }

        return names;
      });
  }

  getMember() {
    this.backendApiService.setNodeEndpoint();
    return this.backendApiService.get('node_path')
      .map((res: any) => {
        let teams: any[] = [];
        for (let event of res.data) {
          teams.push(event);
        }
        return teams;
      });
  }

  getTeamListPager(): number[] {
    let pager: number[] = [];
    pager.push(this.teamtListCurrentPage, this.teamListTotalPages);
    return pager;
  }
}