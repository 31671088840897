import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {BackendApiService} from '../../shared/services/backend-api/backend-api.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {Donate} from '../../models/donate';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Block} from '../../shared/models/block.model';
import {Observable} from 'rxjs';
import {finalize, switchMap} from 'rxjs/operators';
import {BlockHandlerService} from '../../shared/services/block-handler/block-handler.service';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { ProjectModel } from '../../models/project';
import { ProjectService } from '../../shared/services/project/project.service';
import { FormService } from '../../services/form/form.service';

declare let Stripe: Function;

@Component({
  selector: 'app-donate-form',
  templateUrl: './donate-form.component.html',
  styleUrls: ['./donate-form.component.scss']
})
export class DonateFormComponent implements OnInit, AfterViewInit {
  public popupState: PopupState;
  public possiblePopupStates = PopupState;
  public submitAttempt = false;
  public validSubmit = false;
  public validSubmitPortal = false;
  public donateForm: FormGroup;
  public customerPortalForm: FormGroup;
  public isProjectListOpen = false;
  public projects: ProjectModel[];
  public selectedProject: ProjectModel;
  public activeTab: 'single'|'monthly'|'portal' = 'single';
  private stripe: any;
  blockObservable: Observable<Block> = new Observable();
  @ViewChild('termsAndConditions') termsAndConditions: TermsAndConditionsComponent;

  constructor(private backendApiService: BackendApiService,
              private ngxSmartModalService: NgxSmartModalService,
              private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private projectService: ProjectService,
              private blockHandlerService: BlockHandlerService,
              public formService: FormService
            ) {
    this.createDonateForm();
    this.createCustomerPortalForm();
  }

  protected createDonateForm() {
    this.donateForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      country: ['', [Validators.required]],
      donationType: ['usd', [Validators.required]],
      donationAmount: ['', [Validators.required, Validators.min(5)]],
      donationFrequency: ['single', [Validators.required]],
      project: [''],
      purpose: ['', Validators.required],
      termsAndConditions: [null, [Validators.required]],
      captcha: ['']
    });
  }

  private createCustomerPortalForm() {
    this.customerPortalForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  private getPostData(): Donate {
    // todo this class is not really needed anymore but w/e
    return new Donate(
      this.donateForm.get('firstName').value,
      this.donateForm.get('lastName').value,
      this.donateForm.get('email').value,
      this.donateForm.get('country').value,
      this.donateForm.get('donationType').value,
      this.donateForm.get('donationAmount').value,
      this.donateForm.get('donationFrequency').value,
      this.donateForm.get('project').value,
      this.donateForm.get('purpose').value,
      this.donateForm.get('termsAndConditions').value,
      this.termsAndConditions.captchaValue
    );
  }

  ngAfterViewInit() {
    // subscribe to router event, but only after html is rendered so modal finds it's reference
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.status) {
        this.openDonatePopup(params.status);
        // remove query param (well, all params)
        this.router.navigate(
          [],
          {
            queryParams: {},
            replaceUrl: true
          }
        );
      }
    });
  }

  ngOnInit() {
    this.blockObservable = this.blockHandlerService.getBlockData('donate-right-text');
    this.projectService.getProjects().pipe(
      switchMap(res => {
        this.projects = res;

        this.backendApiService.setRestV2Endpoint();
        return this.backendApiService.get('get-key');
      }),
      switchMap(res => {
        this.stripe = Stripe(res.apiKey);

        return this.activatedRoute.queryParams;
      })
    ).subscribe(params => {
      if (params && params.project) {
        const project = this.projects.find(project => project.nid.toString() === params.project);
        if (project) {
          this.selectProject(project);
        }
      }
    });
  }

  public openDonatePopup(state: PopupState) {
    this.popupState = state;
    this.ngxSmartModalService.getModal('myDonateModal').open();
  }

  public openCustomerPortalPopup(state: PopupState, error?: string) {
    this.popupState = state;
    const modal = this.ngxSmartModalService.getModal('customerPortalModal');

    if (error) {
      modal.setData({error}, true);
    }

    modal.open();
  }

  public selectProject(project): void {
    if (!this.selectedProject || this.selectedProject.nid !== project.nid) {
      this.selectedProject = project;
      this.setFormValue('project', project.title);
      this.setFormValue('purpose', '');
      this.donateForm.get('purpose').clearValidators();
    } else {
      this.selectedProject = null;
      this.donateForm.get('purpose').setValidators([Validators.required]);
      this.setFormValue('project', '');
    }
    this.donateForm.get('purpose').updateValueAndValidity({onlySelf: true});
  }

  public submit(): void {
    this.submitAttempt = true;
    if (this.donateForm.valid && this.termsAndConditions.captchaSuccessful) {
      this.validSubmit = true;
      this.backendApiService.setRestV2Endpoint();
      this.backendApiService.rawPost('payments', this.getPostData(), false).subscribe((res: any) => {
        this.stripe.redirectToCheckout({
          sessionId: res.sessionId
        }).then((result) => {
          this.reset();
          this.openDonatePopup(PopupState.Error);
        });
      }, (err) => {
        this.validSubmit = false;
        this.openDonatePopup(PopupState.Error);
      });
    }
  }

  public submitCustomerPortal(): void {
    if (this.customerPortalForm.valid) {
      this.validSubmitPortal = true;
      this.backendApiService.setRestV2Endpoint();
      this.backendApiService.rawPost('stripe/get-portal', this.customerPortalForm.value, false).pipe(
        finalize(() => this.validSubmitPortal = false)
      ).subscribe(
        (res: any) => {
          const status = res.status;

          if (status) {
            this.resetCustomerPortalForm();
            this.openCustomerPortalPopup(PopupState.Success);
          } else {
            this.openCustomerPortalPopup(PopupState.Error, res.error);
          }
        },
        (err: any) => {
          this.openCustomerPortalPopup(PopupState.Error, err.error.error);
        }
      );
    }
  }

  // Resets the form and submit attempt.
  public reset() {
    this.donateForm.reset();
    this.submitAttempt = false;
    this.validSubmit = false;
  }

  public resetCustomerPortalForm() {
    this.customerPortalForm.reset();
    this.validSubmitPortal = false;
  }

  closeModal(): void {
    this.ngxSmartModalService.getModal('myDonateModal').close();
  }

  closeCustomerPortalModal(): void {
    this.ngxSmartModalService.getModal('customerPortalModal').close();
  }

  public setFormValue(name: string, value: string) {
    this.donateForm.get(name).setValue(value);
  }

  public onAmountBlur(e) {
    const errors = this.donateForm.get('donationAmount').errors;

    if (errors && errors.min) {
      e.target.value = null;
    }
  }

  public selectCustomerPortalTab(): void {
    this.activeTab = 'portal';
  }

  public selectSingleDonationTab(): void {
    this.activeTab = 'single';
    this.setFormValue('donationFrequency', 'single');
  }

  public selectMonthlyDonationTab(): void {
    this.activeTab = 'monthly';
    this.setFormValue('donationFrequency', 'monthly');
  }
}

export enum PopupState {
  Success = 1,
  Cancelled = 2,
  Error = 3,
}
