export class SocialChannel {
    private type: string;
    private followLink: string;
    private count: number;

    constructor(type: string) {
        this.type = type;
        this.decideFollowLink();
    }

    getType(): string {
        return this.type;
    }

    getCount(): string {
        if (this.count > 1000) {
            return String(Math.floor(this.count / 1000)).concat('k');
        }
        return String(this.count);
    }

    getLinkName(): string {
        return this.followLink;
    }

    setCount(count: number) {
        this.count = count;
    }

    private decideFollowLink() {
        switch(this.type) { 
            case 'facebook': { 
                this.followLink = 'like';
                break; 
            } 
            case 'twitter': { 
                this.followLink = 'follow';
                break; 
            } 
            case 'youtube': { 
                this.followLink = 'subscribe';
                break; 
            } 
         } 
    }
}