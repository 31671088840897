import {Component, OnInit} from '@angular/core';
import {PagetitleService} from '../../services/page-title/pagetitle.service';
import {HeaderService} from '../../services/header/header.service';
import {BreadCrumbItem} from '../../shared/models/breadcrumb-item.model';
import {BreadcrumbService} from '../../shared/services/breadcrumb/breadcrumb.service';
import { BackendApiService } from '../../shared/services/backend-api/backend-api.service';
import { BasicPage } from '../../shared/models/basic-page.model';
import { SidebarTemplate } from '../../shared/models/sidebar-template.model';

@Component({
  selector: 'app-ouractionspage',
  templateUrl: './ouractionspage.component.html',
  styleUrls: ['./ouractionspage.component.scss']
})
export class OuractionspageComponent implements OnInit {
  public sidebar: SidebarTemplate;

  constructor(
    private pageTitleService: PagetitleService,
    private headerService: HeaderService,
    private breadCrumbService: BreadcrumbService,
    private backendApiService: BackendApiService
  ) { }

  ngOnInit() {
    this.breadCrumbService.setBreadCrumb([new BreadCrumbItem('Events')]);
    this.headerService.setHeaderImage('assets/img/header-img-our-actions.jpg');
    this.pageTitleService.setPageTitle('Events');
    this.pageTitleService.setPrintedTitle('Events');
    this.headerService.shareButtons(false);

    this.backendApiService.setBaseEndpoint();
    this.backendApiService.get('/sidebar-template/events').subscribe(res => {
      const page = new BasicPage().deserialize(res);
      this.sidebar = page.sidebarTemplate;
    });
  }
}
